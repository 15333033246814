import React, { Fragment } from "react";
import { Dispatcher, Constants } from "../../../flux";

function NavbarDrawer(props) {
  const handleToggleSidebar = () => {
    Dispatcher.dispatch({
        actionType: Constants.TOGGLE_SIDEBAR_DESKTOP
      });
  };

  return (
    <Fragment>
      {/* eslint-disable-next-line */}
      <a
        href="#"
        onClick={handleToggleSidebar}
        className="nav-link nav-link-icon toggle-sidebar d-lg-inline d-md-none d-sm-none text-center desktop-drawer"
      >
        <i className="material-icons">&#xE5D2;</i>
      </a>
    </Fragment>
  );
}

export default NavbarDrawer;
