import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Container, Card, CardBody, FormInput } from "shards-react";
import { Link, Redirect } from "react-router-dom";
import { getAllContacts } from "../../actions/contact";
import ContactItem from "./ContactItem";
import Spinner from "../layout/Spinner";
import Alert from "../layout/Alert";
import DataTable from "react-data-table-component";

const Contacts = ({
  getAllContacts,
  auth: { isAuthenticated, user },
  contact: { contacts, loading },
}) => {
  const [searchText, setSearchText] = useState("");
  useEffect(() => {
    getAllContacts(searchText);
  }, [getAllContacts, searchText]);

  const [contactsArray, setContactsArray] = useState([]);

  useEffect(() => {
    let cont = [];

    cont =
      contacts && contacts.length > 0
        ? contacts.map((c, index) => ({ ...c, frontEndIndex: index + 1 }))
        : [];
    setContactsArray(cont);
  }, [contacts]);

  const columns = [
    {
      name: "#",
      selector: "frontEndIndex",
      sortable: true,
    },
    {
      name: "Name",
      selector: "name",
      sortable: true,
      cell: (row) => <Link to={`/contacts/view/${row.id}`}>{row.name}</Link>,
    },
    {
      name: "Email",
      selector: "email",
      sortable: true,
    },
    {
      name: "Mobile",
      selector: "mobile",
      sortable: true,
    },
    {
      name: "Contact/Agent",
      selector: "type",
      sortable: true,
    },
    {
      name: "Action",
      // maxWidth: '600px', // when using custom you should use width or maxWidth, otherwise, the table will default to flex grow behavior
      cell: (row) => <ContactItem key={row.id} contact={row} />,
      right: true,
    },
  ];

  return isAuthenticated && user !== null && user.role !== "Admin" ? (
    <Redirect to={"/dashboard"} />
  ) : loading ? (
    <Spinner />
  ) : (
    <Container fluid className="main-content-container px-4">
      {/* Page Header */}
      <div className="page-header py-4">
        <Card small className="card-post card-post--1">
          <CardBody>
            <h1 className="text-primary">
              Contacts / Agents
              <Link
                to="/contacts/add"
                className="btn btn-primary pull-right mb-2"
              >
                Add Contact
              </Link>
            </h1>
            <FormInput
              placeholder="Search by name, email or mobile"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              name="name"
              className="mb-3"
            />
            {contactsArray && contactsArray.length > 0 ? (
              <DataTable
                columns={columns}
                data={contactsArray}
                pagination={true}
                paginationPerPage={30}
                striped={true}
                pointerOnHover={true}
              />
            ) : (
              <h4>No contact found</h4>
            )}
            <div className="mt-3">
              <Alert />
            </div>
          </CardBody>
        </Card>
      </div>
    </Container>
  );
};

Contacts.propTypes = {
  auth: PropTypes.object.isRequired,
  getAllContacts: PropTypes.func.isRequired,
  contact: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  contact: state.contact,
});

export default connect(mapStateToProps, { getAllContacts })(Contacts);
