import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// import Moment from "react-moment";
import { Link } from "react-router-dom";

import { deleteUser } from "../../actions/staff";

function UserItem({ user, deleteUser }) {
  return (
    <Fragment>
      <Link to={`/users/edit/${user.id}`} title="Edit">
        <i className="fa fa-pencil pr-2 text-warning" />
      </Link>{" "}
      <Link
        to="#"
        title="Delete"
        onClick={() => {
          if (window.confirm("Are you sure?")) {
            deleteUser(user.id);
          }
        }}
      >
        <i className="fa fa-trash pr-2 text-danger" />
      </Link>{" "}
      <Link to={`/users/view/${user.id}`}>
        <i className="fa fa-eye" />
      </Link>
    </Fragment>
  );
}

UserItem.propTypes = {
  deleteUser: PropTypes.func.isRequired,
};

export default connect(null, { deleteUser })(UserItem);
