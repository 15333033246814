import React, { useState } from "react";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { setAlert } from "../../actions/alert";
import { register } from "../../actions/auth";
import PropTypes from "prop-types";
import {
  Card,
  CardBody,
  FormInput,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormSelect
} from "shards-react";
import Alert from "../../components/layout/Alert";

const Register = ({ setAlert, register, isAuthenticated }) => {
  const [showPassword, togglePassword] = useState(false);
  const [showConfirmPassword, toggleConfirmPassword] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    password2: "",
    role: ""
  });
  const { name, email, password, password2, role } = formData;

  const onChangeHandler = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const submitForm = async (e) => {
    e.preventDefault();
    if (password !== password2) {
      setAlert("Password not matched", "danger");
    } else {
      register({ name, email, password, role });
    }
  };

  // Redirect if logged in
  if (isAuthenticated) {
    return <Redirect to="/dashboard" />;
  }

  return (
        <Card small className="card-post card-post--1 login-card">
          <CardBody>
            <Alert />
            <h1 className="large text-primary">Sign Up</h1>
            <p className="">
              <i className="fas fa-user"></i> Create Your Account
            </p>
            <form className="form col col-12" onSubmit={submitForm}>
            <FormGroup>
                <InputGroup className="mb-3">
                  <InputGroupAddon type="prepend">
                    <InputGroupText>
                      <i className="fa fa-user" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <FormInput
                    placeholder="Username"
                    value={name}
                    onChange={onChangeHandler}
                    name="name"
                  />
                </InputGroup>               
              </FormGroup>

              <FormGroup>
                <InputGroup className="mb-3">
                  <InputGroupAddon type="prepend">
                    <InputGroupText>
                      <i className="fa fa-user-secret" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <FormInput
                    placeholder="Email Address"
                    value={email}
                    onChange={onChangeHandler}
                    name="email"
                  />
                </InputGroup>
                <small className="form-text">
                  This site uses Gravatar so if you want a profile image, use a
                  Gravatar email
                </small>
              </FormGroup>
              <FormGroup>
                <InputGroup className="mb-3">
                  <InputGroupAddon type="prepend">
                    <InputGroupText>
                      {/* eslint-disable-next-line */}
                      <a onClick={() => togglePassword(!showPassword)}>
                        <i
                          className={`fa fa-eye${showPassword ? "-slash" : ""}`}
                        />
                      </a>
                    </InputGroupText>
                  </InputGroupAddon>
                  <FormInput
                    type={showPassword ? "text" : "password"}
                    placeholder="Password"
                    name="password"
                    minLength="6"
                    value={password}
                    onChange={onChangeHandler}
                  />
                </InputGroup>
              </FormGroup>

              <FormGroup>
                <InputGroup className="mb-3">
                  <InputGroupAddon type="prepend">
                    <InputGroupText>
                      {/* eslint-disable-next-line */}
                      <a onClick={() => toggleConfirmPassword(!showConfirmPassword)}>
                        <i
                          className={`fa fa-eye${showConfirmPassword ? "-slash" : ""}`}
                        />
                      </a>
                    </InputGroupText>
                  </InputGroupAddon>
                  <FormInput
                    type={showConfirmPassword ? "text" : "password"}
                    placeholder="Confirm Password"
                    name="password2"
                    minLength="6"
                    value={password2}
                    onChange={onChangeHandler}
                  />
                </InputGroup>
              </FormGroup>

              <FormGroup>
                <InputGroup className="mb-3">
                  <FormSelect
                    value={role}
                    onChange={onChangeHandler}
                    name="role"
                  >
                    <option value="">Choose</option>
                    <option value="Staff">Staff</option>
                    <option value="Admin">Admin</option>
                  </FormSelect>
                </InputGroup>
              </FormGroup>

              <input
                type="submit"
                className="bg-primary text-white text-center rounded btn btn-primary"
                value="Register"
              />
            </form>
            <p className="my-1">
              Already have an account? <Link to="/login">Sign In</Link>
            </p>
          </CardBody>
        </Card>
  );
};

Register.propTypes = {
  setAlert: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});
export default connect(mapStateToProps, { setAlert, register })(Register);
