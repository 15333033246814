import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Container, Card, CardBody, FormInput } from "shards-react";
import { Link, Redirect } from "react-router-dom";
import { getAllUsers } from "../../actions/staff";
import UserItem from "./UserItem";
import Spinner from "../../components/layout/Spinner";

import Alert from "../../components/layout/Alert";
import DataTable from "react-data-table-component";

const Users = ({
  getAllUsers,
  auth: { isAuthenticated, user },
  staff: { users, loading },
}) => {
  const [userText, setUserText] = useState("");
  const [usersArray, setUsersArray] = useState([]);

  useEffect(() => {
    let u = [];
    u =
      users &&
      users.length > 0 &&
      users.map((u, index) => ({
        ...u,
        frontEndIndex: index + 1,
      }));

    setUsersArray(u);
  }, [users]);

  useEffect(() => {
    getAllUsers(userText);
  }, [getAllUsers, userText]);

  const columns = [
    {
      name: "#",
      selector: "frontEndIndex",
      sortable: true,
    },
    {
      name: "Name",
      selector: "name",
      sortable: true,
      cell: (row) => <Link to={`/users/view/${row.id}`}>{row.name}</Link>,
    },
    {
      name: "Email",
      selector: "email",
      sortable: true,
    },
    {
      name: "Action",
      // maxWidth: '600px', // when using custom you should use width or maxWidth, otherwise, the table will default to flex grow behavior
      cell: (row) => <UserItem key={row.id} user={row} />,
      right: true,
    },
  ];

  return isAuthenticated && user !== null && user.role !== "Admin" ? (
    <Redirect to={"/dashboard"} />
  ) : loading ? (
    <Spinner />
  ) : (
    <Container fluid className="main-content-container px-4">
      {/* Page Header */}
      <div className="page-header py-4">
        <Card small className="card-post card-post--1">
          <CardBody>
            <h1 className="text-primary">
              User Management
              <Link to="/users/add" className="btn btn-primary pull-right mb-2">
                Add User
              </Link>
            </h1>
            <FormInput
              placeholder="Search by name or email"
              value={userText}
              onChange={(e) => setUserText(e.target.value)}
              name="name"
              className="mb-3"
            />
            {usersArray && usersArray.length > 0 ? (
              <DataTable
                columns={columns}
                data={usersArray}
                pagination={true}
                paginationPerPage={30}
                striped={true}
                pointerOnHover={true}
              />
            ) : (
              <h4>No user found</h4>
            )}
            <div className="mt-3">
              <Alert />
            </div>
          </CardBody>
        </Card>
      </div>
    </Container>
  );
};

Users.propTypes = {
  auth: PropTypes.object.isRequired,
  getAllUsers: PropTypes.func.isRequired,
  staff: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  staff: state.staff,
});

export default connect(mapStateToProps, { getAllUsers })(Users);
