import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { addUser, getUserById } from "../../actions/staff";
import Alert from "../../components/layout/Alert";
import { Formik } from "formik";
import * as yup from "yup";
import {
  Card,
  CardBody,
  FormInput,
  FormGroup,
  InputGroup,
  Container,
  FormSelect,
  InputGroupAddon,
  InputGroupText,
} from "shards-react";

function AddUser({
  getUserById,
  addUser,
  staff: { user, loading },
  match: {
    params: { id },
  },
}) {
  let validationRules = {
    name: yup.string().required().label("*Username"),
    email: yup.string().required().email().label("*E-mail"),
    role: yup.string().required().label("*Role"),
    mobile: yup
      .string()
      .required()
      .label("*Mobile No")
      .min(10, "*Please enter a valid mobile")
      .max(12, "*Please enter a valid mobile"),
  };

  if (!id) {
    validationRules.password = yup
      .string()
      .required()
      .min(6)
      .label("*Password");
    validationRules.password2 = yup
      .string()
      .required()
      .oneOf([yup.ref("password"), null], "*Passwords must match")
      .label("*Confirm Password");
  }

  const [showPassword, togglePassword] = useState(false);
  const [showConfirmPassword, toggleConfirmPassword] = useState(false);
  const [focusedInput, setFocusedInput] = useState();

  useEffect(() => {
    if (id) {
      if (Object.keys(user).length === 0 && user.constructor === Object) {
        getUserById(id);
      }
    }
  }, [id, getUserById, user]);

  return (
    <Formik
      enableReinitialize
      initialValues={{
        name: id ? user.name : "",
        email: id ? user.email : "",
        role: id ? user.role : "",
        mobile: id ? user.mobile : "",
        gst: id ? user.gst : "",
        password: "",
        password2: "",
      }}
      onSubmit={(values) => {
        const formData = {
          name: values.name,
          email: values.email,
          role: values.role,
          mobile: values.mobile,
          password: values.password,
          gst: values.gst,
          id: id,
        };

        addUser(formData);
      }}
      validationSchema={yup.object(validationRules)}
    >
      {(formikProps) => (
        <Container fluid className="main-content-container px-4">
          {/* Page Header */}
          <div className="page-header py-4">
            <Card small className="card-post card-post--1">
              <CardBody>
                <h1 className="large text-primary">
                  {id ? "Edit" : "Add"} User
                </h1>
                <p className="">
                  <i className="fas fa-user"></i> {id ? "Update" : "Create"} An
                  Account
                </p>
                <form className="form col col-lg-6 col-md-12">
                  <FormGroup>
                    <InputGroup className="mb-3">
                      <FormInput
                        onFocus={(e) => setFocusedInput(e.target.name)}
                        placeholder="Username"
                        value={formikProps.values.name}
                        onChange={formikProps.handleChange}
                        name="name"
                        invalid={
                          formikProps.errors && formikProps.errors.name
                            ? true
                            : false
                        }
                      />
                    </InputGroup>
                    <small className="text-danger">
                      {focusedInput === "name" && formikProps.errors.name}
                    </small>
                  </FormGroup>
                  <FormGroup>
                    <InputGroup className="mb-3">
                      <FormInput
                        onFocus={(e) => setFocusedInput(e.target.name)}
                        placeholder="Mobile No."
                        value={formikProps.values.mobile}
                        onChange={formikProps.handleChange}
                        name="mobile"
                        invalid={
                          formikProps.errors && formikProps.errors.mobile
                            ? true
                            : false
                        }
                      />
                    </InputGroup>
                    <small className="text-danger">
                      {focusedInput === "mobile" && formikProps.errors.mobile}
                    </small>
                  </FormGroup>
                  <FormGroup>
                    <InputGroup className="mb-3">
                      <FormInput
                        onFocus={(e) => setFocusedInput(e.target.name)}
                        invalid={
                          formikProps.errors && formikProps.errors.email
                            ? true
                            : false
                        }
                        placeholder="Email Address"
                        value={formikProps.values.email}
                        onChange={formikProps.handleChange}
                        name="email"
                      />
                    </InputGroup>
                    <small className="text-danger">
                      {focusedInput === "email" && formikProps.errors.email}
                    </small>
                  </FormGroup>
                  <FormGroup>
                    <InputGroup className="mb-3">
                      <FormInput
                        onFocus={(e) => setFocusedInput(e.target.name)}
                        invalid={
                          formikProps.errors && formikProps.errors.gst
                            ? true
                            : false
                        }
                        placeholder="GST number"
                        value={formikProps.values.gst}
                        onChange={formikProps.handleChange}
                        name="gst"
                      />
                    </InputGroup>
                    <small className="text-danger">
                      {focusedInput === "gst" && formikProps.errors.gst}
                    </small>
                  </FormGroup>
                  <FormGroup>
                    <InputGroup className="mb-3">
                      <FormSelect
                        onFocus={(e) => setFocusedInput(e.target.name)}
                        invalid={
                          formikProps.errors && formikProps.errors.role
                            ? true
                            : false
                        }
                        value={formikProps.values.role}
                        onChange={formikProps.handleChange}
                        name="role"
                      >
                        <option value="">Choose</option>
                        <option value="Agent">Agent</option>
                        <option value="Admin">Admin</option>
                      </FormSelect>
                    </InputGroup>
                    <small className="text-danger">
                      {focusedInput === "role" && formikProps.errors.role}
                    </small>
                  </FormGroup>
                  <FormGroup>
                    <InputGroup className="mb-3">
                      <InputGroupAddon type="prepend">
                        <InputGroupText>
                          {/* eslint-disable-next-line */}
                          <a onClick={() => togglePassword(!showPassword)}>
                            <i
                              className={`fa fa-eye${
                                showPassword ? "-slash" : ""
                              }`}
                            />
                          </a>
                        </InputGroupText>
                      </InputGroupAddon>
                      <FormInput
                        onFocus={(e) => setFocusedInput(e.target.name)}
                        invalid={
                          formikProps.errors && formikProps.errors.password
                            ? true
                            : false
                        }
                        type={showPassword ? "text" : "password"}
                        placeholder="Password"
                        name="password"
                        minLength="6"
                        value={formikProps.values.password}
                        onChange={formikProps.handleChange}
                      />
                    </InputGroup>
                    <small className="text-danger">
                      {focusedInput === "password" &&
                        formikProps.errors.password}
                    </small>
                  </FormGroup>
                  <FormGroup>
                    <InputGroup className="mb-3">
                      <InputGroupAddon type="prepend">
                        <InputGroupText>
                          {/* eslint-disable-next-line */}
                          <a
                            onClick={() =>
                              toggleConfirmPassword(!showConfirmPassword)
                            }
                          >
                            <i
                              className={`fa fa-eye${
                                showConfirmPassword ? "-slash" : ""
                              }`}
                            />
                          </a>
                        </InputGroupText>
                      </InputGroupAddon>
                      <FormInput
                        onFocus={(e) => setFocusedInput(e.target.name)}
                        invalid={
                          formikProps.errors && formikProps.errors.password2
                            ? true
                            : false
                        }
                        type={showConfirmPassword ? "text" : "password"}
                        placeholder="Confirm Password"
                        name="password2"
                        minLength="6"
                        value={formikProps.values.password2}
                        onChange={formikProps.handleChange}
                      />
                    </InputGroup>
                    <small className="text-danger">
                      {focusedInput === "password2" &&
                        formikProps.errors.password2}
                    </small>
                  </FormGroup>
                  <input
                    type="submit"
                    className="bg-primary text-white text-center rounded btn btn-primary"
                    value="Save"
                    onClick={formikProps.handleSubmit}
                    disabled={!formikProps.isValid}
                  />{" "}
                  <Link to="/users" className="btn btn-secondary">
                    Back
                  </Link>
                </form>

                <div className="mt-3">
                  <Alert />
                </div>
              </CardBody>
            </Card>
          </div>
        </Container>
      )}
    </Formik>
  );
}

AddUser.propTypes = {
  addUser: PropTypes.func.isRequired,
  getUserById: PropTypes.func.isRequired,
  staff: PropTypes.object,
};
const mapStateToProps = (state) => ({
  staff: state.staff,
});

export default connect(mapStateToProps, { addUser, getUserById })(AddUser);
