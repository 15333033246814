import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { addContact, getContactById } from "../../actions/contact";
import Alert from "../layout/Alert";
import { Formik } from "formik";
import * as yup from "yup";
import {
  Card,
  CardBody,
  FormInput,
  FormGroup,
  InputGroup,
  Container,
} from "shards-react";

const validationSchema = yup.object({
  name: yup.string().required().label("*Name"),
  email: yup.string().required().label("*E-mail").email(),
  mobile: yup
    .string()
    .required()
    .label("*Mobile No")
    .min(10, "*Please enter a valid mobile")
    .max(12, "*Please enter a valid mobile"),
  // type: yup.string().required().label("*Type"),
});

function AddContact({
  getContactById,
  addContact,
  contact: { contact, loading },
  match: {
    params: { id },
  },
}) {
  useEffect(() => {
    if (id) {
      if (Object.keys(contact).length === 0 && contact.constructor === Object) {
        getContactById(id);
      }
    }
  }, [id, getContactById, contact]);

  const [focusedInput, setFocusedInput] = useState();

  return (
    <Formik
      enableReinitialize
      initialValues={{
        name: id ? contact.name : "",
        email: id ? contact.email : "",
        mobile: id ? contact.mobile : "",
        // type: id ? contact.type : "",
      }}
      onSubmit={(values) => {
        const formData = {
          name: values.name,
          email: values.email,
          mobile: values.mobile,
          type: "Contact",
          id: id,
        };

        addContact(formData);
      }}
      validationSchema={validationSchema}
    >
      {(formikProps) => (
        <Container fluid className="main-content-container px-4">
          {/* Page Header */}
          <div className="page-header py-4">
            <Card small className="card-post card-post--1">
              <CardBody>
                <h1 className="large text-primary">
                  {id ? "Edit" : "Add"} Contact
                </h1>
                <p className="">
                  <i className="fas fa-user"></i> {id ? "Update" : "Create"}{" "}
                  contact / agent details
                </p>
                <form className="form col col-lg-6 col-md-12">
                  <FormGroup>
                    <InputGroup className="mb-3">
                      <FormInput
                        onFocus={(e) => setFocusedInput(e.target.name)}
                        invalid={
                          formikProps.errors && formikProps.errors.name
                            ? true
                            : false
                        }
                        placeholder="Name"
                        value={formikProps.values.name}
                        onChange={formikProps.handleChange}
                        name="name"
                      />
                    </InputGroup>
                    <small className="text-danger">
                      {focusedInput === "name" && formikProps.errors.name}
                    </small>
                  </FormGroup>
                  <FormGroup>
                    <InputGroup className="mb-3">
                      <FormInput
                        onFocus={(e) => setFocusedInput(e.target.name)}
                        invalid={
                          formikProps.errors && formikProps.errors.email
                            ? true
                            : false
                        }
                        placeholder="Email Address"
                        value={formikProps.values.email}
                        onChange={formikProps.handleChange}
                        name="email"
                        type="email"
                      />
                    </InputGroup>
                    <small className="text-danger">
                      {focusedInput === "email" && formikProps.errors.email}
                    </small>
                  </FormGroup>
                  <FormGroup>
                    <InputGroup className="mb-3">
                      <FormInput
                        onFocus={(e) => setFocusedInput(e.target.name)}
                        invalid={
                          formikProps.errors && formikProps.errors.mobile
                            ? true
                            : false
                        }
                        placeholder="Mobile"
                        value={formikProps.values.mobile}
                        onChange={formikProps.handleChange}
                        name="mobile"
                        type="number"
                      />
                    </InputGroup>
                    <small className="text-danger">
                      {focusedInput === "mobile" && formikProps.errors.mobile}
                    </small>
                  </FormGroup>
                  <input
                    type="submit"
                    className="bg-primary text-white text-center rounded btn btn-primary"
                    value="Save"
                    onClick={formikProps.handleSubmit}
                    disabled={!formikProps.isValid}
                  />{" "}
                  <Link to="/contacts" className="btn btn-secondary">
                    Back
                  </Link>
                </form>
                <div className="mt-3">
                  <Alert />
                </div>
              </CardBody>
            </Card>
          </div>
        </Container>
      )}
    </Formik>
  );
}

AddContact.propTypes = {
  addContact: PropTypes.func.isRequired,
  getContactById: PropTypes.func.isRequired,
  contact: PropTypes.object,
};
const mapStateToProps = (state) => ({
  contact: state.contact,
});

export default connect(mapStateToProps, {
  addContact,
  getContactById,
})(AddContact);
