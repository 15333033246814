import {
  GET_CONTACTS,
  GET_CONTACTS_FAILED,
  DELETE_CONTACT,
  CONTACT_ERROR,
  ADD_CONTACT,
  GET_CONTACT,
  GET_ALL_CONTACTS,
} from "../actions/types";

const initialState = {
  contacts: [],
  allContacts: [],
  loading: true,
  error: {},
  contact: {},
};

export default function Contact(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_ALL_CONTACTS:
      return {
        ...state,
        allContacts: payload,
        loading: false,
        contact: {},
      };
    case GET_CONTACTS:
      return {
        ...state,
        contacts: payload,
        loading: false,
        contact: {},
      };
    case GET_CONTACTS_FAILED:
      return {
        ...state,
        contacts: null,
        loading: false,
        contact: {},
      };
    case DELETE_CONTACT:
      return {
        ...state,
        contacts: state.contacts.filter((contact) => contact.id !== payload),
        loading: false,
        contact: {},
      };
    case CONTACT_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
        contact: {},
      };
    case ADD_CONTACT:
      return {
        ...state,
        contacts: [payload, ...state.contacts],
        loading: false,
        contact: {},
      };
    case GET_CONTACT:
      return {
        ...state,
        contact: payload,
        loading: false,
      };
    default:
      return state;
  }
}
