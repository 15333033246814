import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { getFormulasFromDb } from "../../actions/formula";
import { getAllContactsFromDb } from "../../actions/contact";
import { Formik } from "formik";
import * as yup from "yup";
import {
  addProperty,
  editProperty,
  getPropertyById,
} from "../../actions/property";
import Alert from "../layout/Alert";
import {
  Card,
  CardBody,
  FormInput,
  FormGroup,
  FormSelect,
  InputGroup,
  Container,
} from "shards-react";

const validationSchema = yup.object({
  title: yup.string().required().label("*Title"),
});

function AddProperty({
  getFormulasFromDb,
  getAllContactsFromDb,
  getPropertyById,
  unfilteredFormulas,
  allContacts,
  addProperty,
  editProperty,
  property,
  match: {
    params: { id },
  },
}) {
  useEffect(() => {
    getFormulasFromDb();
    getAllContactsFromDb();
  }, [getFormulasFromDb, getAllContactsFromDb]);

  useEffect(() => {
    if (id) {
      getPropertyById(id);
    }
  }, [id, getPropertyById]);

  const [focusedInput, setFocusedInput] = useState();

  return (
    <Formik
      enableReinitialize
      validationSchema={validationSchema}
      initialValues={{
        title: id ? property.title : "",
        location: id ? property.location : "",
        formula_id: id ? property.formula_id : null,
        referral_id: id ? property.referral_id : null,
      }}
      onSubmit={(values) => {
        const formData = {
          title: values.title,
          location: values.location,
          formula_id: values.formula_id,
          referral_id: values.referral_id,
        };

        id ? editProperty(id, formData) : addProperty(formData);
      }}
    >
      {(formikProps) => (
        <Container fluid className="main-content-container px-4">
          {/* Page Header */}
          <div className="page-header py-4">
            <Card small className="card-post card-post--1">
              <CardBody>
                <h1 className="large text-primary">
                  {id ? "Edit Property" : "Add New Property"}
                </h1>
                <form className="form col col-lg-6 col-md-12">
                  <FormGroup>
                    <InputGroup className="mb-3">
                      <FormInput
                        onFocus={(e) => setFocusedInput(e.target.name)}
                        placeholder="Title"
                        value={formikProps.values.title}
                        onChange={formikProps.handleChange}
                        name="title"
                        invalid={
                          formikProps.errors && formikProps.errors.title
                            ? true
                            : false
                        }
                      />
                    </InputGroup>
                    <small className="text-danger">
                      {focusedInput === "title" && formikProps.errors.title}
                    </small>
                  </FormGroup>
                  <FormGroup>
                    <InputGroup className="mb-3">
                      <FormInput
                        onFocus={(e) => setFocusedInput(e.target.name)}
                        placeholder="Location"
                        value={formikProps.values.location}
                        onChange={formikProps.handleChange}
                        name="location"
                        invalid={
                          formikProps.errors && formikProps.errors.location
                            ? true
                            : false
                        }
                      />
                    </InputGroup>
                    <small className="text-danger">
                      {focusedInput === "location" &&
                        formikProps.errors.location}
                    </small>
                  </FormGroup>
                  <FormGroup>
                    <InputGroup className="mb-3">
                      <FormSelect
                        onFocus={(e) => setFocusedInput(e.target.name)}
                        value={formikProps.values.formula_id}
                        onChange={formikProps.handleChange}
                        name="formula_id"
                        invalid={
                          formikProps.errors && formikProps.errors.formula_id
                            ? true
                            : false
                        }
                      >
                        <option value={null}>Select Formula</option>
                        {unfilteredFormulas.length > 0 &&
                          unfilteredFormulas.map((formula) => (
                            <option key={formula.id} value={formula.id}>
                              {formula.title}
                            </option>
                          ))}
                      </FormSelect>
                    </InputGroup>
                    <small className="text-danger">
                      {focusedInput === "formula_id" &&
                        formikProps.errors.formula_id}
                    </small>
                  </FormGroup>
                  <FormGroup>
                    <InputGroup className="mb-3">
                      <FormSelect
                        onFocus={(e) => setFocusedInput(e.target.name)}
                        value={formikProps.values.referral_id}
                        onChange={formikProps.handleChange}
                        name="referral_id"
                        invalid={
                          formikProps.errors && formikProps.errors.referral_id
                            ? true
                            : false
                        }
                      >
                        <option value={null}>
                          Select referral person if any
                        </option>
                        {allContacts.length > 0 &&
                          allContacts.map((contact) => (
                            <option value={contact.id} key={contact.id}>
                              {contact.name}
                            </option>
                          ))}
                      </FormSelect>
                    </InputGroup>
                    <small className="text-danger">
                      {focusedInput === "referral_id" &&
                        formikProps.errors.referral_id}
                    </small>
                  </FormGroup>
                  <input
                    type="submit"
                    className="bg-primary text-white text-center rounded btn btn-primary"
                    value="Save"
                    onClick={formikProps.handleSubmit}
                    disabled={!formikProps.isValid}
                  />{" "}
                  <Link to="/properties" className="btn btn-secondary">
                    Back
                  </Link>
                </form>
                <div className="mt-3">
                  <Alert />
                </div>
              </CardBody>
            </Card>
          </div>
        </Container>
      )}
    </Formik>
  );
}

AddProperty.propTypes = {
  getFormulasFromDb: PropTypes.func.isRequired,
  getAllContactsFromDb: PropTypes.func.isRequired,
  addProperty: PropTypes.func.isRequired,
  editProperty: PropTypes.func.isRequired,
  id: PropTypes.number,
  getPropertyById: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  unfilteredFormulas: state.formula.unfilteredFormulas,
  allContacts: state.contact.allContacts,
  property: state.property.property,
});

export default connect(mapStateToProps, {
  getFormulasFromDb,
  getAllContactsFromDb,
  addProperty,
  editProperty,
  getPropertyById,
})(AddProperty);
