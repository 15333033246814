import React from "react";
import axios from "axios";
import { setAlert } from "./alert";
import {
  GET_BRANCHES,
  GET_BRANCHES_FAILED,
  DELETE_BRANCH,
  BRANCH_ERROR,
  ADD_BRANCH,
  GET_BRANCH,
  GET_ALL_BRANCHES,
} from "./types";

// GET all Branches for dropdown
export const getAllBranchesForDropdown = () => async (dispatch) => {
  try {
    const res = await axios.get(`/api/branches`);

    dispatch({
      type: GET_ALL_BRANCHES,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_BRANCHES_FAILED,
    });
  }
};

// GET Branches
export const getAllBranches = (search) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/branches/search/${search}`);

    dispatch({
      type: GET_BRANCHES,
      payload: res.data,
    });
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
    }
    dispatch({
      type: GET_BRANCHES_FAILED,
    });
  }
};

export const MemoizedGetAllBranches = React.memo(getAllBranches);

// Delete Branch
export const deleteBranch = (id) => async (dispatch) => {
  try {
    await axios.delete(`/api/branches/${id}`);

    dispatch({
      type: DELETE_BRANCH,
      payload: id,
    });

    dispatch(setAlert("Branch Removed", "success"));
  } catch (err) {
    console.log(err);

    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
    }

    dispatch({
      type: BRANCH_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

// Add contact
export const addBranch = (formData) => async (dispatch) => {
  try {
    let res = null;

    if (formData.id) {
      res = await axios.put(`/api/branches/edit/${formData.id}`, formData);
    } else {
      res = await axios.post("/api/branches/", formData);
    }
    dispatch({
      type: ADD_BRANCH,
      payload: res.data,
    });

    dispatch(
      setAlert(formData.id ? "Branch updated" : "Branch created", "success")
    );
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
      //dispatch(setAlert(errors.map(error => `${error.msg}`), "danger"));
    }
    dispatch({
      type: BRANCH_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

// Get contact by id
export const getBranchById = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/branches/${id}`);

    dispatch({
      type: GET_BRANCH,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: BRANCH_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};
