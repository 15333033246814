import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { getAllBranchesForDropdown } from "../../actions/branch";
import { getFormulasFromDb } from "../../actions/formula";
import { editAccount, getAccountById } from "../../actions/account";
import { getAllUnfilteredUsers } from "../../actions/staff";
import Alert from "../layout/Alert";
import { Formik } from "formik";
import validationSchema from "./AccountValidationSchema";
import { setAlert } from "../../actions/alert";
import {
  Card,
  CardBody,
  FormInput,
  FormGroup,
  InputGroup,
  Container,
  InputGroupAddon,
  FormSelect,
  InputGroupText,
} from "shards-react";

function EditAccount({
  getAccountById,
  getAllBranchesForDropdown,
  getAllUnfilteredUsers,
  getFormulasFromDb,
  editAccount,
  branches,
  users,
  setAlert,
  account: { viewAccount },
  match: {
    params: { accountId },
  },
}) {
  useEffect(() => {
    if (accountId) {
      const params = {
        accountId,
      };
      getAccountById(params);
    }
  }, [accountId]);
  const [account, setAccount] = useState();

  useEffect(() => {
    viewAccount && setAccount(viewAccount);
  }, [viewAccount]);

  const property = account && account.Property;

  const formula = property && property.Formula;

  const [focusedInput, setFocusedInput] = useState();

  const [formData, setFormData] = useState({
    property_sale_value: "",
    commission: "",
    commission_amount: "",
    overspending_cost: "",
    referral_share: "",
    referral_share_amount: "",
    branch_id: "",
    branch_share: "",
    branch_share_amount: "",
    office_commission: "",
    office_commission_amount: "",
    gross_commission_excluding_gst: "",
    admin_cost: "",
    branding_share: "",
    branding_share_amount: "",
    internal_commission: "",
    internal_commission_amount: "",
    agents: [],
  });

  useEffect(() => {
    if (account) {
      setFormData({
        ...formData,
        property_sale_value:
          account && account.property_sale_value
            ? account.property_sale_value
            : "",
        commission: account && account.commission ? account.commission : "",
        commission_amount: account
          ? ((account.commission / 100) * account.property_sale_value).toFixed(
              2
            )
          : "",
        overspending_cost:
          account && account.overspending_cost ? account.overspending_cost : "",
        referral_share:
          account && account.referral_share ? account.referral_share : "",
        referral_share_amount:
          account && account.referral_share_amount
            ? account.referral_share_amount
            : "",
        branch_id: account && account.branch_id ? account.branch_id : null,
        branch_share:
          account && account.branch_share ? account.branch_share : "0",
        branch_share_amount:
          account && account.branch_share_amount
            ? account.branch_share_amount
            : "0",
        office_commission:
          account && account.office_commission ? account.office_commission : "",
        office_commission_amount:
          account && account.office_commission_amount
            ? account.office_commission_amount
            : "",
        gross_commission_excluding_gst:
          account && account.gross_commission_excluding_gst
            ? account.gross_commission_excluding_gst
            : "",
        admin_cost: account && account.admin_cost ? account.admin_cost : "",
        branding_share:
          account && account.branding_share ? account.branding_share : "",
        branding_share_amount:
          account && account.branding_share_amount
            ? account.branding_share_amount
            : "",
        internal_commission:
          account && account.internal_commission
            ? account.internal_commission
            : "",
        internal_commission_amount:
          account && account.internal_commission_amount
            ? account.internal_commission_amount
            : "",
        properties_id:
          account && account.properties_id ? account.properties_id : "",
        agents: account && account.AccountAgents ? account.AccountAgents : "",
      });
    }
  }, [account]);

  const [inHand, setInHand] = useState(0);

  const {
    property_sale_value,
    commission,
    commission_amount,
    overspending_cost,
    referral_share,
    referral_share_amount,
    branch_id,
    branch_share,
    branch_share_amount,
    office_commission,
    office_commission_amount,
    gross_commission_excluding_gst,
    admin_cost,
    branding_share,
    branding_share_amount,
    internal_commission,
    internal_commission_amount,
    agents,
  } = formData;

  const onChangeHandler = (e) => {
    if (e.target.name === "property_sale_value") {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const percentToCost = (e) => {
    if (e.target.name === "referral_share") {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
        [`${e.target.name}_amount`]: (
          (e.target.value / 100) *
          (commission_amount * 1 - overspending_cost * 1)
        ).toFixed(2),
      });
    } else if (
      e.target.name === "branch_share" ||
      e.target.name === "office_commission"
    ) {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
        [`${e.target.name}_amount`]: (
          (e.target.value / 100) *
          (inHand - gross_commission_excluding_gst)
        ).toFixed(2),
      });
    } else if (
      e.target.name === "branding_share" ||
      e.target.name === "internal_commission"
    ) {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
        [`${e.target.name}_amount`]: (
          (e.target.value / 100) *
          (office_commission_amount - admin_cost)
        ).toFixed(2),
      });
    } else {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
        [`${e.target.name}_amount`]: (
          (e.target.value / 100) *
          property_sale_value
        ).toFixed(2),
      });
    }
  };

  const agentPercentToCost = (e) => {
    setFormData({
      ...formData,
      agents: agents.map((agent, index) =>
        e.target.name + "" !== index + ""
          ? agent
          : {
              ...agent,
              agent_commission: e.target.value,
              agent_commission_amount: (
                (e.target.value / 100) *
                (office_commission_amount - admin_cost)
              ).toFixed(2),
            }
      ),
    });
  };

  const costToPercent = (e) => {
    if (e.target.name === "referral_share_amount") {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
        [e.target.name.slice(0, e.target.name.length - 7)]: (
          (e.target.value / (commission_amount * 1 - overspending_cost * 1)) *
          100
        ).toFixed(2),
      });
    } else if (
      e.target.name === "branch_share_amount" ||
      e.target.name === "office_commission_amount"
    ) {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
        [e.target.name.slice(0, e.target.name.length - 7)]: (
          (e.target.value / (inHand - gross_commission_excluding_gst)) *
          100
        ).toFixed(2),
      });
    } else if (
      e.target.name === "branding_share_amount" ||
      e.target.name === "internal_commission_amount"
    ) {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
        [e.target.name.slice(0, e.target.name.length - 7)]: (
          (e.target.value / (office_commission_amount - admin_cost)) *
          100
        ).toFixed(2),
      });
    } else {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
        [e.target.name.slice(0, e.target.name.length - 7)]: (
          (e.target.value / property_sale_value) *
          100
        ).toFixed(2),
      });
    }
  };

  const agentCostToPercent = (e) =>
    setFormData({
      ...formData,
      agents: agents.map((agent, index) =>
        e.target.name + "" !== index + ""
          ? agent
          : {
              ...agent,
              agent_commission_amount: e.target.value,
              agent_commission: (
                (e.target.value / (office_commission_amount - admin_cost)) *
                100
              ).toFixed(2),
            }
      ),
    });

  const handleAgentChange = (e) => {
    setFormData({
      ...formData,
      agents: agents.map((agent, index) =>
        e.target.name + "" === index + ""
          ? {
              ...agent,
              agent_id: e.target.value,
            }
          : agent
      ),
    });
  };

  const deleteAgent = (agentIndex) => {
    setFormData({
      ...formData,
      agents: agents.filter(
        (agent, index) => index + "" !== agentIndex + "" && agent
      ),
    });
  };

  const submitForm = async () => {
    const params = {
      property_sale_value: property_sale_value ? property_sale_value : 0,
      commission: commission ? commission : 0,
      commission_amount: commission_amount ? commission_amount : 0,
      overspending_cost: overspending_cost ? overspending_cost : 0,
      referral_share: referral_share ? referral_share : 0,
      referral_share_amount: referral_share_amount ? referral_share_amount : 0,
      branch_id: branch_id ? branch_id : null,
      branch_share: branch_share ? branch_share : 0,
      branch_share_amount: branch_share_amount ? branch_share_amount : 0,
      office_commission: office_commission ? office_commission : 0,
      office_commission_amount: office_commission_amount
        ? office_commission_amount
        : 0,
      gross_commission_excluding_gst: gross_commission_excluding_gst
        ? gross_commission_excluding_gst
        : 0,
      admin_cost: admin_cost ? admin_cost : 0,
      branding_share: branding_share ? branding_share : 0,
      branding_share_amount: branding_share_amount ? branding_share_amount : 0,
      internal_commission: internal_commission ? internal_commission : 0,
      internal_commission_amount: internal_commission_amount
        ? internal_commission_amount
        : 0,
      agents: agents,
      properties_id:
        account && account.properties_id ? account.properties_id : "",
    };

    editAccount(params, accountId);
  };

  useEffect(() => {
    getAllBranchesForDropdown();
    getAllUnfilteredUsers();
    getFormulasFromDb();
  }, [getAllBranchesForDropdown, getAllUnfilteredUsers, getFormulasFromDb]);

  useEffect(() => {
    setInHand(commission_amount - overspending_cost - referral_share_amount);

    setFormData({
      ...formData,
      gross_commission_excluding_gst:
        focusedInput !== "gross_commission_excluding_gst"
          ? (
              (commission_amount - overspending_cost - referral_share_amount) /
              11
            ).toFixed(2)
          : gross_commission_excluding_gst,
    });
  }, [commission_amount, overspending_cost, referral_share_amount]);

  useEffect(() => {
    if (formula && commission >= 0 && property_sale_value > 0) {
      setFormData({
        ...formData,
        commission_amount:
          formula.commission_type === "percentage"
            ? ((commission / 100) * property_sale_value).toFixed(2)
            : commission_amount,
        commission:
          formula.commission_type !== "percentage"
            ? ((commission_amount / property_sale_value) * 100).toFixed(2)
            : commission,
      });
    }
  }, [property_sale_value]);

  useEffect(() => {
    if (formula) {
      setFormData({
        ...formData,
        referral_share_amount:
          formula.referral_share_type === "percentage"
            ? (
                (referral_share / 100) *
                (commission_amount * 1 - overspending_cost * 1)
              ).toFixed(2)
            : referral_share_amount,
      });
    }
  }, [commission_amount, overspending_cost]);

  useEffect(() => {
    if (formula && inHand > 0) {
      setFormData({
        ...formData,

        branch_share_amount:
          formula.branch_share_type === "percentage"
            ? (
                (branch_share / 100) *
                (inHand - gross_commission_excluding_gst)
              ).toFixed(2)
            : branch_share_amount,
        office_commission_amount:
          formula.office_commission_type === "percentage"
            ? (
                (office_commission / 100) *
                (inHand - gross_commission_excluding_gst)
              ).toFixed(2)
            : office_commission_amount,

        branch_share:
          formula.branch_share_type !== "percentage"
            ? (
                (branch_share_amount /
                  (inHand - gross_commission_excluding_gst)) *
                100
              ).toFixed(2)
            : branch_share,
        office_commission:
          formula.office_commission_type !== "percentage"
            ? (
                (office_commission_amount /
                  (inHand - gross_commission_excluding_gst)) *
                100
              ).toFixed(2)
            : office_commission,
      });
    }
  }, [gross_commission_excluding_gst]);

  const [
    agentCommissionAmountFocused,
    setAgentCommissionAmountFocused,
  ] = useState();
  const [agentCommissionFocused, setAgentCommissionFocused] = useState();

  useEffect(() => {
    if (formula) {
      if (agents && agents.length > 0) {
        let i = 0;

        for (i = 0; i < agents.length; i += 1) {
          if (
            formula.FormulaAgents[i].agent_commission_type === "percentage" &&
            i !== agentCommissionAmountFocused
          ) {
            let agentsCopy = [];
            agentsCopy = agents;
            agentsCopy[i] = {
              ...agentsCopy[i],
              agent_commission_amount: (
                (agents[i].agent_commission / 100) *
                (office_commission_amount - admin_cost)
              ).toFixed(2),
            };
            setFormData({
              ...formData,
              agents: agentsCopy,
            });
          } else if (
            formula.FormulaAgents[i].agent_commission_type !== "percentage" &&
            i !== agentCommissionFocused
          ) {
            let agentsCopy = [];
            agentsCopy = agents;
            agentsCopy[i] = {
              ...agentsCopy[i],
              agent_commission: (
                (agents[i].agent_commission_amount /
                  (office_commission_amount - admin_cost)) *
                100
              ).toFixed(2),
            };
            setFormData({
              ...formData,
              agents: agentsCopy,
            });
          }
        }
      }

      setFormData({
        ...formData,
        branding_share_amount:
          formula.branding_share_type === "percentage"
            ? (
                (branding_share / 100) *
                (office_commission_amount - admin_cost)
              ).toFixed(2)
            : branding_share_amount,
        internal_commission_amount:
          formula.internal_commission_type === "percentage"
            ? (
                (internal_commission / 100) *
                (office_commission_amount - admin_cost)
              ).toFixed(2)
            : internal_commission_amount,
        branding_share:
          formula.branding_share_type !== "percentage"
            ? (
                (branding_share_amount /
                  (office_commission_amount - admin_cost)) *
                100
              ).toFixed(2)
            : branding_share,
        internal_commission:
          formula.internal_commission_type !== "percentage"
            ? (
                (internal_commission_amount /
                  (office_commission_amount - admin_cost)) *
                100
              ).toFixed(2)
            : internal_commission,
      });
    }
  }, [office_commission_amount, admin_cost]);

  useEffect(() => {
    if (formula && internal_commission_amount >= 0) {
      if (agents && agents.length > 0) {
        let i = 0;

        for (i = 0; i < agents.length; i += 1) {
          if (i !== agentCommissionAmountFocused) {
            let agentsCopy = [];
            agentsCopy = agents;
            agentsCopy[i] = {
              ...agentsCopy[i],
              agent_commission_amount: (
                (agents[i].agent_commission / 100) *
                (office_commission_amount - admin_cost)
              ).toFixed(2),
            };
            setFormData({
              ...formData,
              agents: agentsCopy,
            });
          }
        }
      }
    }
  }, [agents]);

  return (
    <Formik
      enableReinitialize
      initialValues={{
        property_sale_value: formData && formData.property_sale_value,
        commission: formData && formData.commission,
        commission_amount: formData && formData.commission_amount,
        overspending_cost: formData && formData.overspending_cost,
        referral_share: formData && formData.referral_share,
        referral_share_amount: formData && formData.referral_share_amount,
        branch_id: formData && formData.branch_id,
        branch_share: formData && formData.branch_share,
        branch_share_amount: formData && formData.branch_share_amount,
        office_commission: formData && formData.office_commission,
        office_commission_amount: formData && formData.office_commission_amount,
        gross_commission_excluding_gst:
          formData && formData.gross_commission_excluding_gst,
        admin_cost: formData && formData.admin_cost,
        branding_share: formData && formData.branding_share,
        branding_share_amount: formData && formData.branding_share_amount,
        internal_commission: formData && formData.internal_commission,
        internal_commission_amount:
          formData && formData.internal_commission_amount,
        agents: formData && formData.agents,
      }}
      onSubmit={(values) => {
        let agentsSum = 0;

        agentsSum =
          agents &&
          agents.length &&
          agents
            .map((item) => {
              return item.agent_commission !== ""
                ? parseFloat(item.agent_commission)
                : 0;
            })
            .reduce((prev, next) => prev + next);

        if (isNaN(agentsSum)) {
          agentsSum = 0;
        }

        if (parseFloat(branch_share) + parseFloat(office_commission) !== 100) {
          setAlert(
            "Sum of Branch share and Office commission should exactly be 100%",
            "danger"
          );
        } else if (
          parseFloat(branding_share) +
            parseFloat(internal_commission) +
            parseFloat(agentsSum) !==
          100
        ) {
          setAlert(
            "Sum of Branding, Internal and Agents commission should exactly be 100%",
            "danger"
          );
        } else {
          submitForm();
        }
      }}
      validationSchema={validationSchema}
    >
      {(formikProps) => (
        <Container fluid className="main-content-container px-4">
          {/* Page Header */}
          <div className="page-header py-4">
            <Card small className="card-post card-post--1">
              <CardBody>
                <h1 className="large text-primary">
                  Property {property && property.title}
                </h1>
                <h4 className="">Accounts</h4>
                <form className="form col col-lg-6 col-md-12">
                  <FormGroup>
                    <label>Amount</label>
                    <InputGroup className="">
                      <FormInput
                        onFocus={(e) => setFocusedInput(e.target.name)}
                        invalid={
                          formikProps.errors &&
                          formikProps.errors.property_sale_value
                            ? true
                            : false
                        }
                        placeholder="Amount"
                        value={property_sale_value}
                        onChange={onChangeHandler}
                        name="property_sale_value"
                        type="number"
                      />
                    </InputGroup>
                    <small className="text-danger">
                      {focusedInput === "property_sale_value" &&
                        formikProps.errors.property_sale_value}
                    </small>
                  </FormGroup>
                  <FormGroup>
                    <label>Commission</label>
                    <div className="row">
                      <InputGroup className="col col-4">
                        <InputGroupAddon type="prepend">
                          <InputGroupText>%</InputGroupText>
                        </InputGroupAddon>
                        <FormInput
                          onFocus={(e) => setFocusedInput(e.target.name)}
                          invalid={
                            formikProps.errors && formikProps.errors.commission
                              ? true
                              : false
                          }
                          placeholder="Commission"
                          value={commission}
                          onChange={percentToCost}
                          name="commission"
                          type="number"
                        />
                      </InputGroup>

                      <InputGroup className="col col-8">
                        <InputGroupAddon type="prepend">
                          <InputGroupText>$</InputGroupText>
                        </InputGroupAddon>
                        <FormInput
                          onFocus={(e) => setFocusedInput(e.target.name)}
                          invalid={
                            formikProps.errors &&
                            formikProps.errors.commission_amount
                              ? true
                              : false
                          }
                          placeholder="Commission cost"
                          value={commission_amount}
                          onChange={costToPercent}
                          name="commission_amount"
                          type="number"
                        />
                      </InputGroup>
                    </div>
                    <small className="text-danger">
                      {focusedInput === "commission" &&
                        formikProps.errors.commission}
                    </small>
                    <small className="text-danger">
                      {focusedInput === "commission_amount" &&
                        formikProps.errors.commission_amount}
                    </small>
                  </FormGroup>
                  <FormGroup>
                    <label>Overspending cost</label>
                    <InputGroup className="">
                      <InputGroupAddon type="prepend">
                        <InputGroupText>$</InputGroupText>
                      </InputGroupAddon>
                      <FormInput
                        onFocus={(e) => setFocusedInput(e.target.name)}
                        invalid={
                          formikProps.errors &&
                          formikProps.errors.overspending_cost
                            ? true
                            : false
                        }
                        placeholder="Overspending cost"
                        value={overspending_cost}
                        onChange={onChangeHandler}
                        name="overspending_cost"
                        type="number"
                      />
                    </InputGroup>
                    <small className="text-danger">
                      {focusedInput === "overspending_cost" &&
                        formikProps.errors.overspending_cost}
                    </small>
                  </FormGroup>
                  <FormGroup>
                    <label>Referral Cost</label>
                    <div className="row">
                      <InputGroup className="col col-4">
                        <InputGroupAddon type="prepend">
                          <InputGroupText>%</InputGroupText>
                        </InputGroupAddon>
                        <FormInput
                          onFocus={(e) => setFocusedInput(e.target.name)}
                          invalid={
                            formikProps.errors &&
                            formikProps.errors.referral_share
                              ? true
                              : false
                          }
                          placeholder="Referral Cost"
                          value={referral_share}
                          onChange={percentToCost}
                          name="referral_share"
                          type="number"
                        />
                      </InputGroup>
                      <InputGroup className="col col-8">
                        <InputGroupAddon type="prepend">
                          <InputGroupText>$</InputGroupText>
                        </InputGroupAddon>
                        <FormInput
                          onFocus={(e) => setFocusedInput(e.target.name)}
                          invalid={
                            formikProps.errors &&
                            formikProps.errors.referral_share_amount
                              ? true
                              : false
                          }
                          placeholder="Referral cost amount"
                          value={referral_share_amount}
                          onChange={costToPercent}
                          name="referral_share_amount"
                          type="number"
                        />
                      </InputGroup>
                    </div>
                    <small className="text-danger">
                      {focusedInput === "referral_share" &&
                        formikProps.errors.referral_share}
                    </small>
                    <small className="text-danger">
                      {focusedInput === "referral_share_amount" &&
                        formikProps.errors.referral_share_amount}
                    </small>
                  </FormGroup>
                  <FormGroup>
                    <label>Office commission excluding GST</label>
                    <InputGroup className="">
                      <InputGroupAddon type="prepend">
                        <InputGroupText>$</InputGroupText>
                      </InputGroupAddon>
                      <FormInput
                        onFocus={(e) => setFocusedInput(e.target.name)}
                        invalid={
                          formikProps.errors &&
                          formikProps.errors.gross_commission_excluding_gst
                            ? true
                            : false
                        }
                        placeholder="Office Commission exc. GST"
                        value={gross_commission_excluding_gst}
                        onChange={onChangeHandler}
                        name="gross_commission_excluding_gst"
                        type="number"
                      />
                    </InputGroup>
                    <small className="text-danger">
                      {focusedInput === "gross_commission_excluding_gst" &&
                        formikProps.errors.gross_commission_excluding_gst}
                    </small>
                  </FormGroup>
                  <FormGroup>
                    <label>Select Branch If Any</label>
                    <InputGroup className="">
                      <FormSelect
                        onFocus={(e) => setFocusedInput(e.target.name)}
                        invalid={
                          formikProps.errors && formikProps.errors.branch_id
                            ? true
                            : false
                        }
                        value={branch_id}
                        onChange={onChangeHandler}
                        name="branch_id"
                      >
                        <option value="">{"Choose"}</option>
                        {branches.length > 0 &&
                          branches.map((branch) => (
                            <option key={branch.id} value={branch.id}>
                              {branch.title}
                            </option>
                          ))}
                      </FormSelect>
                    </InputGroup>

                    <small className="text-danger">
                      {focusedInput === "branch_id" &&
                        formikProps.errors.branch_id}
                    </small>
                  </FormGroup>
                  <FormGroup>
                    <label>Branch Share</label>
                    <div className="row">
                      <InputGroup className="col col-4">
                        <InputGroupAddon type="prepend">
                          <InputGroupText>%</InputGroupText>
                        </InputGroupAddon>
                        <FormInput
                          onFocus={(e) => setFocusedInput(e.target.name)}
                          invalid={
                            formikProps.errors &&
                            formikProps.errors.branch_share
                              ? true
                              : false
                          }
                          placeholder="Branch Share"
                          value={branch_share}
                          onChange={percentToCost}
                          name="branch_share"
                          type="number"
                        />
                      </InputGroup>
                      <InputGroup className="col col-8">
                        <InputGroupAddon type="prepend">
                          <InputGroupText>$</InputGroupText>
                        </InputGroupAddon>
                        <FormInput
                          onFocus={(e) => setFocusedInput(e.target.name)}
                          invalid={
                            formikProps.errors &&
                            formikProps.errors.branch_share_amount
                              ? true
                              : false
                          }
                          placeholder="Branch Share Amount"
                          value={branch_share_amount}
                          onChange={costToPercent}
                          name="branch_share_amount"
                          type="number"
                        />
                      </InputGroup>
                    </div>
                    <small className="text-danger">
                      {focusedInput === "branch_share" &&
                        formikProps.errors.branch_share}
                    </small>
                    <small className="text-danger">
                      {focusedInput === "branch_share_amount" &&
                        formikProps.errors.branch_share_amount}
                    </small>
                  </FormGroup>
                  <FormGroup>
                    <label>Office commission</label>
                    <div className="row">
                      <InputGroup className="col col-4">
                        <InputGroupAddon type="prepend">
                          <InputGroupText>%</InputGroupText>
                        </InputGroupAddon>
                        <FormInput
                          onFocus={(e) => setFocusedInput(e.target.name)}
                          invalid={
                            formikProps.errors &&
                            formikProps.errors.office_commission
                              ? true
                              : false
                          }
                          placeholder="Office commission"
                          value={office_commission}
                          onChange={percentToCost}
                          name="office_commission"
                          type="number"
                        />
                      </InputGroup>
                      <InputGroup className="col col-8">
                        <InputGroupAddon type="prepend">
                          <InputGroupText>$</InputGroupText>
                        </InputGroupAddon>
                        <FormInput
                          onFocus={(e) => setFocusedInput(e.target.name)}
                          invalid={
                            formikProps.errors &&
                            formikProps.errors.office_commission_amount
                              ? true
                              : false
                          }
                          placeholder="Office commission Amount"
                          value={office_commission_amount}
                          onChange={costToPercent}
                          name="office_commission_amount"
                          type="number"
                        />
                      </InputGroup>
                    </div>
                    <small className="text-danger">
                      {focusedInput === "office_commission" &&
                        formikProps.errors.office_commission}
                    </small>
                    <small className="text-danger">
                      {focusedInput === "office_commission_amount" &&
                        formikProps.errors.office_commission_amount}
                    </small>
                  </FormGroup>
                  <FormGroup>
                    <label>Admin cost</label>
                    <InputGroup className="">
                      <InputGroupAddon type="prepend">
                        <InputGroupText>$</InputGroupText>
                      </InputGroupAddon>
                      <FormInput
                        onFocus={(e) => setFocusedInput(e.target.name)}
                        invalid={
                          formikProps.errors && formikProps.errors.admin_cost
                            ? true
                            : false
                        }
                        placeholder="Admin cost"
                        value={admin_cost}
                        onChange={onChangeHandler}
                        name="admin_cost"
                        type="number"
                      />
                    </InputGroup>
                    <small className="text-danger">
                      {focusedInput === "admin_cost" &&
                        formikProps.errors.admin_cost}
                    </small>
                  </FormGroup>
                  <FormGroup>
                    <label>Branding cost</label>
                    <div className="row">
                      <InputGroup className="col col-4">
                        <InputGroupAddon type="prepend">
                          <InputGroupText>%</InputGroupText>
                        </InputGroupAddon>
                        <FormInput
                          onFocus={(e) => setFocusedInput(e.target.name)}
                          invalid={
                            formikProps.errors &&
                            formikProps.errors.branding_share
                              ? true
                              : false
                          }
                          placeholder="Branding cost"
                          value={branding_share}
                          onChange={percentToCost}
                          name="branding_share"
                          type="number"
                        />
                      </InputGroup>
                      <InputGroup className="col col-8">
                        <InputGroupAddon type="prepend">
                          <InputGroupText>$</InputGroupText>
                        </InputGroupAddon>
                        <FormInput
                          onFocus={(e) => setFocusedInput(e.target.name)}
                          invalid={
                            formikProps.errors &&
                            formikProps.errors.branding_share_amount
                              ? true
                              : false
                          }
                          placeholder="Branding cost Amount"
                          value={branding_share_amount}
                          onChange={costToPercent}
                          name="branding_share_amount"
                          type="number"
                        />
                      </InputGroup>
                    </div>

                    <small className="text-danger">
                      {focusedInput === "branding_share" &&
                        formikProps.errors.branding_share}
                    </small>
                    <small className="text-danger">
                      {focusedInput === "branding_share_amount" &&
                        formikProps.errors.branding_share_amount}
                    </small>
                  </FormGroup>
                  <FormGroup>
                    <label>Internal commission</label>
                    <div className="row">
                      <InputGroup className="col col-4">
                        <InputGroupAddon type="prepend">
                          <InputGroupText>%</InputGroupText>
                        </InputGroupAddon>
                        <FormInput
                          onFocus={(e) => setFocusedInput(e.target.name)}
                          invalid={
                            formikProps.errors &&
                            formikProps.errors.internal_commission
                              ? true
                              : false
                          }
                          placeholder="Internal commission"
                          value={internal_commission}
                          onChange={percentToCost}
                          name="internal_commission"
                          type="number"
                        />
                      </InputGroup>
                      <InputGroup className="col col-8">
                        <InputGroupAddon type="prepend">
                          <InputGroupText>$</InputGroupText>
                        </InputGroupAddon>
                        <FormInput
                          onFocus={(e) => setFocusedInput(e.target.name)}
                          invalid={
                            formikProps.errors &&
                            formikProps.errors.internal_commission_amount
                              ? true
                              : false
                          }
                          placeholder="Internal commission Amount"
                          value={internal_commission_amount}
                          onChange={costToPercent}
                          name="internal_commission_amount"
                          type="number"
                        />
                      </InputGroup>
                    </div>

                    <small className="text-danger">
                      {focusedInput === "internal_commission" &&
                        formikProps.errors.internal_commission}
                    </small>
                    <small className="text-danger">
                      {focusedInput === "internal_commission_amount" &&
                        formikProps.errors.internal_commission_amount}
                    </small>
                  </FormGroup>
                  {agents &&
                    agents.map((agent, index) => (
                      <Fragment key={agent.id}>
                        <FormGroup>
                          <label>Select Agent If Any</label>
                          {index !== 0 && (
                            <i
                              type="button"
                              className="fa fa-trash pr-2 text-danger ml-3"
                              onClick={() => deleteAgent(index)}
                            />
                          )}

                          <InputGroup className="">
                            <FormSelect
                              onFocus={(e) =>
                                setFocusedInput(`agent${e.target.name}`)
                              }
                              invalid={
                                formikProps.errors &&
                                formikProps.errors.agents &&
                                formikProps.errors.agents[index] &&
                                formikProps.errors.agents[index].agent_id
                                  ? true
                                  : false
                              }
                              value={agents[index].agent_id}
                              onChange={handleAgentChange}
                              name={index}
                            >
                              <option value="">Choose</option>

                              {users.length > 0 &&
                                users.map((user) => (
                                  <option key={user.id} value={user.id}>
                                    {user.name}
                                  </option>
                                ))}
                            </FormSelect>
                          </InputGroup>
                          <small className="text-danger">
                            {focusedInput === `agent${index}` &&
                              formikProps.errors.agents &&
                              formikProps.errors.agents[index] &&
                              formikProps.errors.agents[index].agent_id}
                          </small>
                        </FormGroup>
                        <FormGroup>
                          <label>Agent commission</label>
                          <div className="row">
                            <InputGroup className="col col-4">
                              <InputGroupAddon type="prepend">
                                <InputGroupText>%</InputGroupText>
                              </InputGroupAddon>
                              <FormInput
                                onFocus={(e) => {
                                  setFocusedInput(`agentPer${e.target.name}`);
                                  setAgentCommissionFocused(index);
                                }}
                                invalid={
                                  formikProps.errors &&
                                  formikProps.errors.agents &&
                                  formikProps.errors.agents[index] &&
                                  formikProps.errors.agents[index]
                                    .agent_commission
                                    ? true
                                    : false
                                }
                                placeholder="Agent commission"
                                value={agents[index].agent_commission}
                                onChange={agentPercentToCost}
                                name={index}
                                type="number"
                              />
                            </InputGroup>
                            <InputGroup className="col col-8">
                              <InputGroupAddon type="prepend">
                                <InputGroupText>$</InputGroupText>
                              </InputGroupAddon>
                              <FormInput
                                onFocus={(e) => {
                                  setFocusedInput(
                                    `agentAmount${e.target.name}`
                                  );
                                  setAgentCommissionAmountFocused(index);
                                }}
                                invalid={
                                  formikProps.errors &&
                                  formikProps.errors.agents &&
                                  formikProps.errors.agents[index] &&
                                  formikProps.errors.agents[index]
                                    .agent_commission_amount
                                    ? true
                                    : false
                                }
                                placeholder="Agent commission Amount"
                                value={agents[index].agent_commission_amount}
                                onChange={agentCostToPercent}
                                name={index}
                                type="number"
                              />
                            </InputGroup>
                          </div>
                          <small className="text-danger">
                            {focusedInput === `agentPer${index}` &&
                              formikProps.errors.agents &&
                              formikProps.errors.agents[index] &&
                              formikProps.errors.agents[index].agent_commission}
                          </small>
                          <small className="text-danger">
                            {focusedInput === `agentAmount${index}` &&
                              formikProps.errors.agents &&
                              formikProps.errors.agents[index] &&
                              formikProps.errors.agents[index]
                                .agent_commission_amount}
                          </small>
                        </FormGroup>
                      </Fragment>
                    ))}
                  <input
                    type="submit"
                    className="bg-primary text-white text-center rounded btn btn-primary"
                    value="Save"
                    onClick={formikProps.handleSubmit}
                    disabled={!formikProps.isValid}
                  />{" "}
                  <Link to="/accounts" className="btn btn-secondary">
                    Back
                  </Link>
                </form>
                <div className="mt-3">
                  <Alert />
                </div>
              </CardBody>
            </Card>
          </div>
        </Container>
      )}
    </Formik>
  );
}

EditAccount.propTypes = {
  getAllBranchesForDropdown: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  account: PropTypes.object.isRequired,
  getFormulasFromDb: PropTypes.func.isRequired,
  getAllUnfilteredUsers: PropTypes.func.isRequired,
  editAccount: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
  getAccountById: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  branches: state.branch.branchesForDropdown,
  users: state.staff.unfilteredUsers,

  account: state.account,
});

export default connect(mapStateToProps, {
  getAllBranchesForDropdown,
  getAllUnfilteredUsers,
  getFormulasFromDb,
  editAccount,
  setAlert,
  getAccountById,
})(EditAccount);
