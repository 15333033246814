import axios from "axios";
import { setAlert } from "./alert";
import {
  FILTER_DEDUCTIONS,
  CREATE_DEDUCTION,
  EDIT_DEDUCTION,
  DELETE_DEDUCTION,
  GET_DEDUCTION_BY_ID,
  DEDUCTION_ERROR,
} from "./types";

// Get deduction by ID
export const getDeductionById = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/deductions/${id}`);
    dispatch({
      type: GET_DEDUCTION_BY_ID,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: DEDUCTION_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

// Filter deductions
export const filterDeductions = (params) => async (dispatch) => {
  try {
    const res = await axios.post(`/api/deductions/filter`, params);
    dispatch({
      type: FILTER_DEDUCTIONS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: DEDUCTION_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

// Create deduction
export const createDeduction = (formData) => async (dispatch) => {
  try {
    const res = await axios.post(`/api/deductions`, formData);

    dispatch({
      type: CREATE_DEDUCTION,
      payload: res.data,
    });

    dispatch(setAlert("Deduction Created", "success"));
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
    }
    dispatch({
      type: DEDUCTION_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

// Edit deduction
export const editDeduction = (formData, id) => async (dispatch) => {
  try {
    const res = await axios.put(`/api/deductions/edit/${id}`, formData);

    dispatch({
      type: EDIT_DEDUCTION,
      payload: res.data,
    });

    dispatch(setAlert("Deduction Updated", "success"));
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
    }
    dispatch({
      type: DEDUCTION_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

// Delete deduction
export const deleteDeduction = (id, reloadDeductions = false) => async (
  dispatch
) => {
  try {
    const res = await axios.delete(`/api/deductions/${id}`);

    dispatch({
      type: DELETE_DEDUCTION,
      payload: id,
    });
    dispatch(setAlert("Deduction Deleted", "success"));
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
    }
    dispatch({
      type: DEDUCTION_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};
