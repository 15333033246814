import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { addBranch, getBranchById } from "../../actions/branch";
import Alert from "../layout/Alert";
import { Formik } from "formik";
import * as yup from "yup";
import {
  Card,
  CardBody,
  FormInput,
  FormGroup,
  InputGroup,
  Container,
} from "shards-react";

const validationSchema = yup.object({
  title: yup.string().required().label("*Title"),
  location: yup.string().required().label("*Location"),
});

function AddBranch({
  getBranchById,
  addBranch,
  branch: { branch, loading },
  match: {
    params: { id },
  },
}) {
  const [focusedInput, setFocusedInput] = useState();

  useEffect(() => {
    if (id) {
      if (Object.keys(branch).length === 0 && branch.constructor === Object) {
        getBranchById(id);
      }
    }
  }, [id, getBranchById, branch]);

  return (
    <Formik
      enableReinitialize
      initialValues={{
        title: id ? branch.title : "",
        location: id ? branch.location : "",
      }}
      onSubmit={(values) => {
        const formData = {
          title: values.title,
          location: values.location,
          id: id,
        };

        addBranch(formData);
      }}
      validationSchema={validationSchema}
    >
      {(formikProps) => (
        <Container fluid className="main-content-container px-4">
          {/* Page Header */}
          <div className="page-header py-4">
            <Card small className="card-post card-post--1">
              <CardBody>
                <h1 className="large text-primary">
                  {id ? "Edit" : "Add"} Branch
                </h1>
                <p className="">
                  <i className="fas fa-user"></i> {id ? "Update" : "Create"}{" "}
                  branch details
                </p>
                <form className="form col col-lg-6 col-md-12">
                  <FormGroup>
                    <InputGroup className="mb-3">
                      <FormInput
                        onFocus={(e) => setFocusedInput(e.target.name)}
                        invalid={
                          formikProps.errors && formikProps.errors.title
                            ? true
                            : false
                        }
                        placeholder="Title"
                        value={formikProps.values.title}
                        onChange={formikProps.handleChange}
                        name="title"
                      />
                    </InputGroup>
                    <small className="text-danger">
                      {focusedInput === "title" && formikProps.errors.title}
                    </small>
                  </FormGroup>
                  <FormGroup>
                    <InputGroup className="mb-3">
                      <FormInput
                        onFocus={(e) => setFocusedInput(e.target.name)}
                        invalid={
                          formikProps.errors && formikProps.errors.location
                            ? true
                            : false
                        }
                        placeholder="Location"
                        value={formikProps.values.location}
                        onChange={formikProps.handleChange}
                        name="location"
                      />
                    </InputGroup>
                    <small className="text-danger">
                      {focusedInput === "location" &&
                        formikProps.errors.location}
                    </small>
                  </FormGroup>
                  <input
                    type="submit"
                    className="bg-primary text-white text-center rounded btn btn-primary"
                    value="Save"
                    onClick={formikProps.handleSubmit}
                    disabled={!formikProps.isValid}
                  />{" "}
                  <Link to="/branches" className="btn btn-secondary">
                    Back
                  </Link>
                </form>
                <div className="mt-3">
                  <Alert />
                </div>
              </CardBody>
            </Card>
          </div>
        </Container>
      )}
    </Formik>
  );
}

AddBranch.propTypes = {
  getBranchById: PropTypes.func.isRequired,
  addBranch: PropTypes.func.isRequired,
  branch: PropTypes.object,
};
const mapStateToProps = (state) => ({
  branch: state.branch,
});

export default connect(mapStateToProps, { getBranchById, addBranch })(
  AddBranch
);
