import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { getContactById } from "../../actions/contact";
import Alert from "../layout/Alert";
import { Card, CardBody, Container } from "shards-react";
import Moment from "react-moment";

function ViewContact({
  getContactById,
  contact: { contact, loading },
  match: {
    params: { id },
  },
}) {
  useEffect(() => {
    if (id) {
      getContactById(id);
    }
  }, [id, getContactById, loading]);

  return (
    !loading && (
      <Container fluid className="main-content-container px-4">
        {/* Page Header */}
        <div className="page-header py-4">
          <Card small className="card-post card-post--1">
            <CardBody>
              <Alert />
              <h1 className="large text-primary">
                {contact.name ? `${contact.name}'s Contact` : ""}
              </h1>
              <p>
                {" "}
                <strong>Name:</strong> {contact.name} <br />
                <strong>Email:</strong> {contact.email}
                <br />
                <strong>Mobile:</strong> {contact.mobile}
                <br />
                <strong>Type:</strong> {contact.type}
                <br />
                <strong>Joined:</strong>{" "}
                <Moment format={"Do MMM YYYY"}>{contact.createdAt}</Moment>{" "}
              </p>
              <Link to="/contacts" className="btn btn-secondary">
                Back
              </Link>{" "}
              <Link to={`/contacts/edit/${id}`} className="lead">
                Click here to Edit
              </Link>
            </CardBody>
          </Card>
        </div>
      </Container>
    )
  );
}

ViewContact.propTypes = {
  getContactById: PropTypes.func.isRequired,
  contact: PropTypes.object,
};
const mapStateToProps = (state) => ({
  contact: state.contact,
});

export default connect(mapStateToProps, { getContactById })(ViewContact);
