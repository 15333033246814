import React from "react";
import { Container, Card, CardBody } from "shards-react";
import Alert from "../components/layout/Alert";

function Dashboard() {
  return (
    <Container fluid className="main-content-container px-4">
      {/* Page Header */}
      <div className="page-header py-4 text-center">
        <Card small className="card-post card-post--1">
          <CardBody>
            <Alert />
            <h1>Dashboard</h1>
            <p className="lead">Coming Soon..!!</p>
          </CardBody>
        </Card>
      </div>
    </Container>
  );
}

export default Dashboard;
