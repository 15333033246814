import {
  FILTER_DEDUCTIONS,
  CREATE_DEDUCTION,
  EDIT_DEDUCTION,
  DELETE_DEDUCTION,
  GET_DEDUCTION_BY_ID,
  DEDUCTION_ERROR,
} from "../actions/types";

const initialState = {
  error: {},
  loading: true,
  deduction: {},
  deductions: [],
  viewDeduction: {},
};

export default function Deduction(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case FILTER_DEDUCTIONS:
      return {
        ...state,
        deductions: payload,
        loading: false,
        deduction: {},
      };
    case GET_DEDUCTION_BY_ID:
      return {
        ...state,
        deduction: payload,
        loading: false,
      };
    case CREATE_DEDUCTION:
      return {
        ...state,
        deduction: payload,
        loading: false,
      };

    case EDIT_DEDUCTION:
      return {
        ...state,
        deduction: payload,
        loading: false,
      };

    case DEDUCTION_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };

    case DELETE_DEDUCTION:
      return {
        ...state,
        error: null,
        loading: false,
        deductions: state.deductions.filter((item) => item.id !== payload),
      };

    default:
      return state;
  }
}
