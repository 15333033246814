import * as yup from "yup";

const validationSchema = yup.object({
  property_sale_value: yup.number().required().label("*Amount"),
  commission: yup.number().required().label("*Commission Percentage"),
  commission_amount: yup.number().required().label("*Commission Amount"),
  overspending_cost: yup.number().required().label("*Overspending Cost"),
  referral_share: yup.number().required().label("*Referral Share Percentage"),
  referral_share_amount: yup
    .number()
    .required()
    .label("*Referral Share Amount"),
  branch_id: yup
    .string()
    .nullable()
    .when("branch_share", {
      is: (val) => parseFloat(val) !== 0,
      then: yup.string().required().label("*Branch"),
    }),
  office_commission: yup
    .number()
    .required()
    .label("*Office Commission Percentage"),
  office_commission_amount: yup
    .number()
    .required()
    .label("*Office Commission Amount"),
  gross_commission_excluding_gst: yup
    .number()
    .required()
    .label("*Office Commission exc GST"),
  admin_cost: yup.number().required().label("*Admin Cost"),
  branding_share: yup.number().required().label("*Branding Share Percentage"),
  branding_share_amount: yup
    .number()
    .required()
    .label("*Branding Share Amount"),
  internal_commission: yup
    .number()
    .required()
    .label("*Internal Commission Percentage"),
  internal_commission_amount: yup
    .number()
    .required()
    .label("*Internal Commission Amount"),

  agents: yup.array().of(
    yup.object().shape({
      agent_commission_type: yup.string(),
      agent_commission: yup
        .number()
        .required()
        .label("*Agent Commission Percentage"),
      agent_commission_amount: yup
        .number()
        .required()
        .label("*Agent Commission Amount"),
      agent_id: yup.string().when("agent_commission", {
        is: (val) => val !== 0,
        then: yup.string().required().label("*Agent"),
      }),
    })
  ),
});

export default validationSchema;
