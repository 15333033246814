import React from "react";
import axios from "axios";
import { setAlert } from "./alert";
import {
  GET_FORMULAS,
  GET_FORMULAS_FAILED,
  DELETE_FORMULA,
  FORMULA_ERROR,
  ADD_FORMULA,
  GET_FORMULA,
  GET_ALL_UNFILTERED_FORMULAS,
} from "./types";

// GET all unfiltered Formulas
export const getFormulasFromDb = () => async (dispatch) => {
  try {
    const res = await axios.get(`/api/formulas`);
    dispatch({
      type: GET_ALL_UNFILTERED_FORMULAS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_FORMULAS_FAILED,
    });
  }
};

// GET Formulas
export const getAllFormulas = (search) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/formulas/search/${search}`);

    dispatch({
      type: GET_FORMULAS,
      payload: res.data,
    });
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
    }
    dispatch({
      type: GET_FORMULAS_FAILED,
    });
  }
};

export const MemoizedGetAllFormulas = React.memo(getAllFormulas);

// Delete Formula
export const deleteFormula = (id) => async (dispatch) => {
  try {
    await axios.delete(`/api/formulas/${id}`);

    dispatch({
      type: DELETE_FORMULA,
      payload: id,
    });

    dispatch(setAlert("Formula Removed", "success"));
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
    }
    dispatch({
      type: FORMULA_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

// Add FORMULA
export const addFormula = (formData, history) => async (dispatch) => {
  try {
    let res = null;

    if (formData.id) {
      res = await axios.put(`/api/formulas/edit/${formData.id}`, formData);
    } else {
      res = await axios.post("/api/formulas/", formData);
      history.push(`/formulas/edit/${res.data.id}`);
    }
    dispatch({
      type: ADD_FORMULA,
      payload: res.data,
    });

    dispatch(
      setAlert(formData.id ? "Formula updated" : "Formula created", "success")
    );
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
    }
    dispatch({
      type: FORMULA_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

// Get formula by id
export const getFormulaById = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/formulas/${id}`);

    dispatch({
      type: GET_FORMULA,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: FORMULA_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};
