import {
  CREATE_ACCOUNT,
  DELETE_ACCOUNT,
  ACCOUNT_ERROR,
  GET_ALL_ACCOUNTS,
  EDIT_ACCOUNT,
  FILTER_ACCOUNTS,
  GET_ACCOUNT_BY_ID,
} from "../actions/types";

const initialState = {
  error: {},
  loading: true,
  account: {},
  accounts: [],
  deductions: [],
  viewAccount: {},
};

export default function Account(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_ALL_ACCOUNTS:
      return {
        ...state,
        accounts: payload,
        loading: false,
      };
    case FILTER_ACCOUNTS:
      return {
        ...state,
        accounts: payload.accounts,
        deductions: payload.deductions,
        loading: false,
      };
    case GET_ACCOUNT_BY_ID:
      return {
        ...state,
        viewAccount: payload,
        loading: false,
      };
    case CREATE_ACCOUNT:
      return {
        ...state,
        account: payload,
        loading: false,
      };

    case EDIT_ACCOUNT:
      return {
        ...state,
        account: payload,
        loading: false,
      };

    case ACCOUNT_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };

    case DELETE_ACCOUNT:
      return {
        ...state,
        error: payload,
        loading: false,
      };

    default:
      return state;
  }
}
