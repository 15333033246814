import React, { Fragment, useEffect, useState } from "react";
import { Nav } from "shards-react";

import SidebarNavItem from "./SidebarNavItem";
import { Store } from "../../../flux";
import { connect } from "react-redux";
import PropTypes from "prop-types";

const SidebarNavItems = ({ auth: { user } }) => {
  const [navItems, changeNavItem] = useState(Store.getSidebarItems());
  useEffect(() => {
    Store.addChangeListener(onChange);
    return () => {
      Store.removeChangeListener(onChange);
    };
  }, []);
  const onChange = () => {
    changeNavItem(Store.getSidebarItems());
  };
  return (
    <div className="nav-wrapper">
      <Nav className="nav--no-borders flex-column">
        {navItems.map((item, idx) =>
          user !== null && user.role !== null ? (
            user.role === "Admin" ||
            (user.role === "Agent" && item.accessToStaff) ? (
              <SidebarNavItem key={idx} item={item} />
            ) : (
              <Fragment />
            )
          ) : (
            <Fragment />
          )
        )}
      </Nav>
    </div>
  );
};
SidebarNavItems.propTypes = {
  auth: PropTypes.object,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps)(SidebarNavItems);
