import React, { useEffect, useState, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Container, Card, CardBody, FormInput } from "shards-react";
import { Link, Redirect } from "react-router-dom";
import { getAllBranches, deleteBranch } from "../../actions/branch";
import Spinner from "../layout/Spinner";
import Alert from "../layout/Alert";
import DataTable from "react-data-table-component";

const Branches = ({
  getAllBranches,
  deleteBranch,
  auth: { isAuthenticated, user },
  branch: { branches, loading },
}) => {
  const [searchText, setSearchText] = useState("");
  const [branchesArray, setBranchesArray] = useState([]);

  useEffect(() => {
    let b = [];
    b =
      branches &&
      branches.length > 0 &&
      branches.map((b, index) => ({
        ...b,
        frontEndIndex: index + 1,
      }));

    setBranchesArray(b);
  }, [branches]);

  useEffect(() => {
    getAllBranches(searchText);
  }, [getAllBranches, searchText]);

  const columns = [
    {
      name: "#",
      selector: "frontEndIndex",
      sortable: true,
    },
    {
      name: "Title",
      selector: "title",
      sortable: true,
    },
    {
      name: "Location",
      selector: "location",
      sortable: true,
    },
    {
      name: "Action",
      // maxWidth: '600px', // when using custom you should use width or maxWidth, otherwise, the table will default to flex grow behavior
      cell: (row) => (
        <Fragment>
          <Link to={`/branches/edit/${row.id}`} title="Edit">
            <i className="fa fa-pencil pr-2 text-warning" />
          </Link>{" "}
          <Link
            to="#"
            title="Delete"
            onClick={() => {
              if (window.confirm("Are you sure?")) {
                deleteBranch(row.id);
              }
            }}
          >
            <i className="fa fa-trash pr-2 text-danger" />
          </Link>
        </Fragment>
      ),
      right: true,
    },
  ];

  return isAuthenticated && user !== null && user.role !== "Admin" ? (
    <Redirect to={"/dashboard"} />
  ) : loading ? (
    <Spinner />
  ) : (
    <Container fluid className="main-content-container px-4">
      {/* Page Header */}
      <div className="page-header py-4">
        <Card small className="card-post card-post--1">
          <CardBody>
            <h1 className="text-primary">
              Branches
              <Link
                to="/branches/add"
                className="btn btn-primary pull-right mb-2"
              >
                Add Branch
              </Link>
            </h1>
            <FormInput
              placeholder="Search by title or location"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              name="name"
              className="mb-3"
            />
            {branchesArray && branchesArray.length > 0 ? (
              <DataTable
                columns={columns}
                data={branchesArray}
                pagination={true}
                paginationPerPage={30}
                striped={true}
                pointerOnHover={true}
              />
            ) : (
              <h4>No branch found</h4>
            )}
            <div className="mt-3">
              <Alert />
            </div>
          </CardBody>
        </Card>
      </div>
    </Container>
  );
};

Branches.propTypes = {
  auth: PropTypes.object.isRequired,
  getAllBranches: PropTypes.func.isRequired,
  deleteBranch: PropTypes.func.isRequired,
  branch: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  branch: state.branch,
});

export default connect(mapStateToProps, { getAllBranches, deleteBranch })(
  Branches
);
