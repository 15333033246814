import axios from "axios";
import { setAlert } from "./alert";
import {
  ADD_PROPERTY,
  PROPERTY_ERROR,
  GET_ALL_PROPERTIES,
  SEARCH_PROPERTY,
  DELETE_PROPERTY,
  EDIT_PROPERTY,
  GET_PROPERTY_BY_ID,
} from "./types";

// Add property
export const addProperty = (formData) => async (dispatch) => {
  try {
    const res = await axios.post(`/api/properties`, formData);

    dispatch({
      type: ADD_PROPERTY,
      payload: res.data,
    });

    dispatch(setAlert("Property Added", "success"));
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
    }
    dispatch({
      type: PROPERTY_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

// Get all properties
export const getAllProperties = () => async (dispatch) => {
  try {
    const res = await axios.get(`/api/properties`);

    dispatch({
      type: GET_ALL_PROPERTIES,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: PROPERTY_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

// Get property by ID
export const getPropertyById = (id, getFormula) => async (dispatch) => {
  try {
    const optionalQuery = getFormula ? true : false;
    const res = await axios.get(
      `/api/properties/${id}?getFormula=${optionalQuery}`
    );

    dispatch({
      type: GET_PROPERTY_BY_ID,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: PROPERTY_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

// Search Properties
export const searchProperties = (searchQuery) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/properties/search/${searchQuery}`);

    dispatch({
      type: SEARCH_PROPERTY,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: PROPERTY_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

// Edit Property
export const editProperty = (id, formData) => async (dispatch) => {
  try {
    const res = await axios.put(`/api/properties/edit/${id}`, formData);

    dispatch({
      type: EDIT_PROPERTY,
      payload: { id: id, data: res.data },
    });

    dispatch(setAlert("Property Updated", "success"));
  } catch (err) {
    dispatch({
      type: PROPERTY_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

// Delete Property
export const deleteProperty = (id) => async (dispatch) => {
  try {
    const res = await axios.delete(`/api/properties/${id}`);

    dispatch({
      type: DELETE_PROPERTY,
      payload: id,
    });

    dispatch(setAlert(res.data.msg, "success"));
  } catch (err) {
    dispatch({
      type: PROPERTY_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};
