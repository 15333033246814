import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Container,
  Card,
  CardBody,
  InputGroup,
  FormInput,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from "shards-react";
import { Redirect, Link } from "react-router-dom";
import Spinner from "../layout/Spinner";
import Alert from "../layout/Alert";
import DataTable from "react-data-table-component";
import Moment from "react-moment";
import { getAllAccounts, filterAccounts } from "../../actions/account";
import { getAllUnfilteredUsers } from "../../actions/staff";
import AccountItem from "./AccountItem";
import moment from "moment";
import { Multiselect } from "multiselect-react-dropdown";
import { CSVLink } from "react-csv";
import { createDeduction } from "../../actions/deduction";
import { currencyFormatter } from "../../utils/helper";

const AccountsListing = ({
  getAllAccounts,
  createDeduction,
  filterAccounts,
  getAllUnfilteredUsers,
  auth: { isAuthenticated, user },
  account: { accounts, loading, deductions },
  staff: { unfilteredUsers },
  extraParams,
}) => {
  const agents =
    unfilteredUsers && unfilteredUsers.filter((item) => item.role === "Agent");

  const [agentOptions, setAgentOptions] = useState({
    objectArray: agents,
    selectedValues: [],
  });

  const [showAddDeductionButton, setShowAddDeductionButton] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [amount, setAmount] = useState(0);
  const [userDeduction, setUserDeduction] = useState(0);

  useEffect(() => {
    if (
      !extraParams &&
      accountsArray &&
      accountsArray.length > 0 &&
      agentOptions &&
      agentOptions.selectedValues.length === 1
    ) {
      setShowAddDeductionButton(true);
    } else {
      setShowAddDeductionButton(false);
    }
  }, [agentOptions]);

  useEffect(() => {
    if (unfilteredUsers) {
      setAgentOptions({
        ...agentOptions,
        objectArray:
          unfilteredUsers &&
          unfilteredUsers.filter((item) => item.role === "Agent"),
      });
    }
  }, [unfilteredUsers]);
  console.log(unfilteredUsers);
  const columns = [
    {
      name: "#",
      selector: "frontEndIndex",
      sortable: true,
    },
    {
      name: "Property Name",
      selector: "Property.title",
      sortable: true,
    },
    {
      name: "Location",
      selector: "Property.location",
      sortable: true,
    },
    {
      name: "Formula",
      selector: (row) =>
        row.Property && row.Property.Formula && row.Property.Formula.title,
      sortable: true,
    },
    {
      name: "Created On",
      selector: "createdAt",
      sortable: true,
      cell: (row) => <Moment format="Do MMM YYYY">{row.createdAt}</Moment>,
    },

    {
      name: "Action",
      // maxWidth: '600px', // when using custom you should use width or maxWidth, otherwise, the table will default to flex grow behavior
      cell: (row) => (
        <AccountItem key={row.id} account={row} reportsPage={false} />
      ),
      right: true,
    },
  ];

  const reportColumns = [
    {
      name: "#",
      selector: "frontEndIndex",
      sortable: true,
    },
    {
      name: "Property Name",
      selector: "Property.title",
      sortable: true,
    },
    {
      name: "Agent Name",
      cell: (row) =>
        agentOptions &&
        agentOptions.selectedValues &&
        agentOptions.selectedValues[0] &&
        agentOptions.selectedValues[0].name,
      sortable: true,
    },
    {
      name: "Sold Date",
      selector: "createdAt",
      sortable: true,
      cell: (row) => <Moment format="Do MMM YYYY">{row.createdAt}</Moment>,
    },
    {
      name: "Office Commission",
      selector: (row) =>
        currencyFormatter.format(row.office_commission_amount * 1),
      sortable: true,
    },
    {
      name: "Agent Commission",
      selector: (row) =>
        row.AccountAgents &&
        row.AccountAgents[0] &&
        (row.AccountAgents[0].User &&
        row.AccountAgents[0].User.gst &&
        row.AccountAgents[0].User.gst !== ""
          ? `${currencyFormatter.format(
              row.AccountAgents[0].agent_commission_amount +
                (row.AccountAgents[0].agent_commission_amount / 10) * 1
            )} (inc. gst)`
          : currencyFormatter.format(
              row.AccountAgents[0].agent_commission_amount * 1
            )),
      sortable: true,
    },

    {
      name: "Action",
      // maxWidth: '600px', // when using custom you should use width or maxWidth, otherwise, the table will default to flex grow behavior
      cell: (row) => (
        <AccountItem key={row.id} account={row} reportsPage={true} />
      ),
      right: true,
    },
  ];

  const onSelect = (selectedList, selectedItem) => {
    setShowAddDeductionButton(false);
    setAgentOptions({
      ...agentOptions,
      selectedValues: selectedList,
    });
  };

  const onRemove = (selectedList, removedItem) => {
    setAgentOptions({
      ...agentOptions,
      selectedValues: selectedList,
    });
  };

  const [accountsArray, setAccountsArray] = useState([]);

  const [reportsArray, setReportsArray] = useState([]);
  useEffect(() => {
    if (
      extraParams &&
      extraParams.isReportsPage === true &&
      accountsArray &&
      accountsArray.length > 0
    ) {
      let data = [];
      accountsArray.forEach((item) => {
        data.push({
          description:
            item &&
            item.Property &&
            `${item.Property.title} ${item.Property.location}`,
          activity: "property",
          amount:
            item.AccountAgents[0].User &&
            item.AccountAgents[0].User.gst &&
            item.AccountAgents[0].User.gst !== ""
              ? item.AccountAgents[0].agent_commission_amount +
                (item.AccountAgents[0].agent_commission_amount / 10) * 1
              : item.AccountAgents[0].agent_commission_amount * 1,
          createdAt: item.createdAt,
        });
      });

      if (deductions && deductions.length) {
        deductions.forEach((item) => {
          data.push({
            description: item.createdAt.replace("T", " ").replace(".000Z", ""),
            activity: "deduction",
            amount: item.total_amount,
            createdAt: item.createdAt,
          });
        });
      }
      data = data.sort((a, b) => a.createdAt.localeCompare(b.createdAt));

      let arr = [
        ["Property/Deduction Date", "Deduction", "Commission", "Total"],
      ];

      let total = 0;
      data.forEach((item) => {
        total =
          item.activity === "deduction"
            ? parseFloat(total - item.amount)
            : parseFloat(total + item.amount);
        arr.push([
          item.description,
          item.activity === "deduction"
            ? currencyFormatter.format(item.amount)
            : "",
          item.activity === "property"
            ? currencyFormatter.format(item.amount)
            : "",
          currencyFormatter.format(total),
        ]);
      });
      arr.push(
        ["", "", "", ""],
        ["", "", "Total", currencyFormatter.format(total)]
      );

      setReportsArray(arr);
    } else {
      setReportsArray([]);
    }
  }, [JSON.stringify(accountsArray)]);

  useEffect(() => {
    if (extraParams && extraParams.isReportsPage === true) {
      if (agentOptions.selectedValues.length > 0) {
        let acc = [];
        acc =
          accounts &&
          accounts.length > 0 &&
          accounts.map((a, index) => ({
            ...a,
            frontEndIndex: index + 1,
          }));
        setAccountsArray(acc);

        let deductionTotal = 0;
        if (deductions && deductions.length) {
          deductions.forEach((item) => {
            deductionTotal += parseFloat(item.total_amount);
          });
        }
        setUserDeduction(deductionTotal);
      }
      if (agentOptions.selectedValues.length === 0) {
        setAccountsArray([]);
      }
    } else {
      let acc = [];
      acc =
        accounts &&
        accounts.length > 0 &&
        accounts.map((a, index) => ({
          ...a,
          frontEndIndex: index + 1,
        }));

      setAccountsArray(acc);
    }
  }, [accounts]);

  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();

  const applyFilter = () => {
    if (!fromDate) {
      let d = new Date();
      d.setMonth(d.getMonth() - 1);
      console.log("fromDate------",moment(d).format("YYYY-MM-DD"));
      setFromDate(moment(d).format("YYYY-MM-DD"));
    }

    if (!toDate) setToDate(moment().format("YYYY-MM-DD"));
    console.log("fromDate----",fromDate);
    console.log("toDate----",toDate);
    const params = {
      fromDate: fromDate ? fromDate : "",
      toDate: toDate ? toDate : "",
      agents: agentOptions.selectedValues.map((s) => s.id),
    };
    setShowAddDeductionButton(true);
    console.log(params);
    filterAccounts(params);
  };

  useEffect(() => {
    getAllAccounts();
    getAllUnfilteredUsers();
  }, [getAllAccounts, getAllUnfilteredUsers]);

  const [amountError, setAmountError] = useState(null);
  const addDeduct = () => {
    if (
      amount &&
      amount > 0 &&
      agentOptions &&
      agentOptions.selectedValues.length === 1
    ) {
      const formData = {
        amount: amount,
        agent_id:
          agentOptions && agentOptions.selectedValues.length === 1
            ? agentOptions.selectedValues[0].id
            : 0,
      };
      createDeduction(formData);
      setAmountError(null);
      setShowModal(false);
    } else {
      setAmountError("Invalid amount or selected agent");
      setShowModal(true);
    }
  };
  return !(isAuthenticated && user !== null) ? (
    <Redirect to={"/dashboard"} />
  ) : loading ? (
    <Spinner />
  ) : (
    <Container fluid className="main-content-container px-4">
      {/* Page Header */}
      <div className="page-header py-4">
        <Card small className="card-post card-post--1">
          <CardBody>
            <h1 className="text-primary">
              {extraParams && extraParams.isReportsPage
                ? "Reports"
                : "Accounts"}
            </h1>
            <div className="row mb-2">
              <div className="col-6">
                <Multiselect
                  options={agentOptions.objectArray} // Options to display in the dropdown
                  selectedValues={agentOptions.selectedValues}
                  onSelect={onSelect} // Function will trigger on select event
                  onRemove={onRemove} // Function will trigger on remove event
                  displayValue="name" // Property name to display in the dropdown options
                  showCheckbox={
                    extraParams && extraParams.isReportsPage ? false : true
                  }
                  selectionLimit={
                    extraParams && extraParams.isReportsPage && "1"
                  }
                />
              </div>

              <InputGroup className="col-2">
                <FormInput
                  type="date"
                  onChange={(e) => {
                    setShowAddDeductionButton(false);
                    setFromDate(e.target.value);
                  }}
                  value={fromDate}
                />
              </InputGroup>

              <InputGroup className="col-2">
                <FormInput
                  type="date"
                  onChange={(e) => {
                    setShowAddDeductionButton(false);
                    setToDate(e.target.value);
                  }}
                  value={toDate}
                />
              </InputGroup>
              <div className="col-2 text-right">
                <button
                  type="button"
                  className="btn btn-success mr-2"
                  onClick={applyFilter}
                >
                  Filter
                </button>

                {showAddDeductionButton &&
                  !extraParams &&
                  accountsArray &&
                  accountsArray.length > 0 && (
                    <button
                      className="btn btn-primary"
                      onClick={() => {
                        setAmountError(null);
                        setAmount(0);
                        setShowModal(true);
                      }}
                    >
                      Add Deduction
                    </button>
                  )}

                {extraParams &&
                  extraParams.isReportsPage &&
                  reportsArray.length > 0 && (
                    <CSVLink
                      data={reportsArray ? reportsArray : ""}
                      filename={`report${+new Date().getTime()}.csv`}
                      className="btn btn-info pull-right"
                    >
                      Export
                    </CSVLink>
                  )}
              </div>
            </div>
            <hr />
            {accountsArray &&
              accountsArray.length > 0 &&
              userDeduction &&
              userDeduction > 0 && (
                <div className="text-danger text-right mb-3">
                  Total Deduction: {currencyFormatter.format(userDeduction)}
                </div>
              )}

            {accountsArray && accountsArray.length > 0 ? (
              <DataTable
                columns={
                  extraParams && extraParams.isReportsPage === true
                    ? reportColumns
                    : columns
                }
                data={user && user.role === "Admin" ? accountsArray : ""}
                pagination={true}
                paginationPerPage={30}
                striped={true}
                pointerOnHover={true}
              />
            ) : (
              <h4>No account found</h4>
            )}
            <div className="mt-3">
              <Alert />
            </div>
            <div>
              <Modal
                open={showModal}
                toggle={() => setShowModal(!showModal)}
                size="md"
              >
                <ModalHeader>Add Deduction</ModalHeader>
                <ModalBody>
                  <div>
                    Deduction will be added to{" "}
                    {agentOptions &&
                      agentOptions.selectedValues.length === 1 &&
                      agentOptions.selectedValues[0].name}
                    's account
                  </div>
                  <hr />
                  <div className="mt-2 mb-1">Amount</div>
                  <FormInput
                    placeholder="Amount"
                    onChange={(e) => setAmount(e.target.value)}
                    value={amount}
                    type="number"
                    step="100"
                    min="0"
                  />
                  <small className="mt-2 text-danger">{amountError}</small>
                </ModalBody>
                <ModalFooter>
                  <button className="btn btn-primary" onClick={addDeduct}>
                    Deduct
                  </button>
                </ModalFooter>
              </Modal>
            </div>
          </CardBody>
        </Card>
      </div>
    </Container>
  );
};

AccountsListing.propTypes = {
  auth: PropTypes.object.isRequired,
  account: PropTypes.object.isRequired,
  agents: PropTypes.object,
  getAllAccounts: PropTypes.func.isRequired,
  createDeduction: PropTypes.func.isRequired,
  getAllUnfilteredUsers: PropTypes.func.isRequired,
  filterAccounts: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  account: state.account,
  staff: state.staff,
});

export default connect(mapStateToProps, {
  getAllAccounts,
  getAllUnfilteredUsers,
  filterAccounts,
  createDeduction,
})(AccountsListing);
