import React, { useEffect, useState, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Container, Card, CardBody, InputGroup, FormInput } from "shards-react";
import { Redirect, Link } from "react-router-dom";
// import Spinner from "../layout/Spinner";
import Alert from "../layout/Alert";
import DataTable from "react-data-table-component";
import Moment from "react-moment";
import { filterDeductions, deleteDeduction } from "../../actions/deduction";
import { getAllUnfilteredUsers } from "../../actions/staff";
import moment from "moment";
import { Multiselect } from "multiselect-react-dropdown";
import { currencyFormatter } from "../../utils/helper";

function Deductions({
  filterDeductions,
  deleteDeduction,
  getAllUnfilteredUsers,
  auth: { isAuthenticated, user },
  deduction: { deductions, loading },
  staff: { unfilteredUsers },
}) {
  const agents =
    unfilteredUsers && unfilteredUsers.filter((item) => item.role === "Agent");

  const [agentOptions, setAgentOptions] = useState({
    objectArray: agents,
    selectedValues: [],
  });

  useEffect(() => {
    if (unfilteredUsers) {
      setAgentOptions({
        ...agentOptions,
        objectArray:
          unfilteredUsers &&
          unfilteredUsers.filter((item) => item.role === "Agent"),
      });
    }
  }, [unfilteredUsers]);

  const onSelect = (selectedList, selectedItem) => {
    setAgentOptions({
      ...agentOptions,
      selectedValues: selectedList,
    });
  };

  const onRemove = (selectedList, removedItem) => {
    setAgentOptions({
      ...agentOptions,
      selectedValues: selectedList,
    });
  };

  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  //const [deductionList, setDeductionList] = useState([]);
  const applyFilter = () => {
    if (!fromDate) {
      let d = new Date();
      d.setMonth(d.getMonth() - 6);
      setFromDate(moment(d).format("YYYY-MM-DD"));
    }

    if (!toDate) setToDate(moment().format("YYYY-MM-DD"));
    const params = {
      fromDate: fromDate ? fromDate : "",
      toDate: toDate ? toDate : "",
      agents: agentOptions.selectedValues.map((s) => s.id),
    };
    filterDeductions(params);
  };

  useEffect(() => {
    applyFilter();
  }, []);

  useEffect(() => {
    getAllUnfilteredUsers();
  }, [getAllUnfilteredUsers]);

  const [deductionList, setDeductionList] = useState([]);

  useEffect(() => {
    if (deductions && deductions.length) {
      setDeductionList(
        deductions.map((a, index) => ({
          ...a,
          frontEndIndex: index + 1,
        }))
      );
    } else {
      setDeductionList(null);
    }
  }, [deductions]);

  const columns = [
    {
      name: "#",
      selector: "frontEndIndex",
      sortable: true,
    },
    {
      name: "Amount",
      sortable: true,
      cell: (row) => currencyFormatter.format(row.amount),
    },
    {
      name: "Agent",
      sortable: true,
      cell: (row) => (
        <Link to={`/users/view/${row.User.id}`} title="Edit">
          {row.User.name}
        </Link>
      ),
    },
    {
      name: "Added On",
      selector: "createdAt",
      sortable: true,
      cell: (row) => (
        <Moment format="Do MMM YYYY hh:mm:ss a">{row.createdAt}</Moment>
      ),
    },

    {
      name: "Action",
      cell: (row) => (
        <Fragment>
          <Link to={`/deductions/edit/${row.id}`} title="Edit">
            <i className="fa fa-pencil pr-2 text-warning" />
          </Link>{" "}
          <Link
            to="#"
            title="Delete"
            onClick={() => {
              if (window.confirm("Are you sure?")) {
                deleteDeduction(row.id);
              }
            }}
          >
            <i className="fa fa-trash pr-2 text-danger" />
          </Link>
        </Fragment>
      ),
      right: true,
    },
  ];

  return !(isAuthenticated && user !== null) ? (
    <Redirect to={"/dashboard"} />
  ) : (
    <Container fluid className="main-content-container px-4">
      {/* Page Header */}
      <div className="page-header py-4">
        <Card small className="card-post card-post--1">
          <CardBody>
            <h1 className="text-primary">Deduction</h1>

            <div className="row mb-2">
              <div className="col-6">
                <Multiselect
                  options={agentOptions.objectArray} // Options to display in the dropdown
                  selectedValues={agentOptions.selectedValues}
                  onSelect={onSelect} // Function will trigger on select event
                  onRemove={onRemove} // Function will trigger on remove event
                  displayValue="name" // Property name to display in the dropdown options
                  showCheckbox={true}
                />
              </div>

              <InputGroup className="col-2">
                <FormInput
                  type="date"
                  onChange={(e) => setFromDate(e.target.value)}
                  value={fromDate}
                />
              </InputGroup>

              <InputGroup className="col-2">
                <FormInput
                  type="date"
                  onChange={(e) => setToDate(e.target.value)}
                  value={toDate}
                />
              </InputGroup>
              <div className="col-2 text-right">
                <button
                  type="button"
                  className={"btn btn-success"}
                  onClick={applyFilter}
                >
                  Filter
                </button>
                <Link to="/deductions/add" className="btn btn-primary ml-2">
                  Add
                </Link>
              </div>
            </div>
            <hr />

            {deductionList && deductionList.length > 0 ? (
              <DataTable
                columns={columns}
                data={user && user.role === "Admin" ? deductionList : ""}
                pagination={true}
                paginationPerPage={30}
                striped={true}
                pointerOnHover={true}
              />
            ) : (
              <h4 className="text-center">
                No record found. Please try with different search filter
              </h4>
            )}
            <div className="mt-3">
              <Alert />
            </div>
          </CardBody>
        </Card>
      </div>
    </Container>
  );
}

Deductions.propTypes = {
  auth: PropTypes.object.isRequired,
  deduction: PropTypes.object.isRequired,
  agents: PropTypes.object,
  getAllUnfilteredUsers: PropTypes.func.isRequired,
  filterDeductions: PropTypes.func.isRequired,
  deleteDeduction: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  deduction: state.deduction,
  staff: state.staff,
});

export default connect(mapStateToProps, {
  getAllUnfilteredUsers,
  filterDeductions,
  deleteDeduction,
})(Deductions);
