import React, { useEffect, useState, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Container, Card, CardBody, FormInput } from "shards-react";
import { Link, Redirect } from "react-router-dom";
import { getAllFormulas, deleteFormula } from "../../actions/formula";
import Spinner from "../layout/Spinner";
import Alert from "../layout/Alert";
import DataTable from "react-data-table-component";
import Moment from "react-moment";

const Formulas = ({
  getAllFormulas,
  deleteFormula,
  auth: { isAuthenticated, user },
  formula: { formulas, loading },
}) => {
  const [searchText, setSearchText] = useState("");
  const [formulasArray, setFormulasArray] = useState([]);

  useEffect(() => {
    let f = [];
    f =
      formulas &&
      formulas.length > 0 &&
      formulas.map((f, index) => ({
        ...f,
        frontEndIndex: index + 1,
      }));

    setFormulasArray(f);
  }, [formulas]);

  useEffect(() => {
    getAllFormulas(searchText);
  }, [getAllFormulas, searchText]);

  const columns = [
    {
      name: "#",
      selector: "frontEndIndex",
      sortable: true,
    },
    {
      name: "Title",
      selector: "title",
      sortable: true,
    },
    {
      name: "Created On",
      selector: "createdAt",
      sortable: true,
      cell: (row) => <Moment format={"Do MMM YYYY"}>{row.createdAt}</Moment>,
    },
    {
      name: "Action",
      // maxWidth: '600px', // when using custom you should use width or maxWidth, otherwise, the table will default to flex grow behavior
      cell: (row) => (
        <Fragment>
          <Link to={`/formulas/edit/${row.id}`} title="Edit">
            <i className="fa fa-pencil pr-2 text-warning" />
          </Link>{" "}
          <Link
            to="#"
            title="Delete"
            onClick={() => {
              if (window.confirm("Are you sure?")) {
                deleteFormula(row.id);
              }
            }}
          >
            <i className="fa fa-trash pr-2 text-danger" />
          </Link>
        </Fragment>
      ),
      right: true,
    },
  ];

  return isAuthenticated && user !== null && user.role !== "Admin" ? (
    <Redirect to={"/dashboard"} />
  ) : loading ? (
    <Spinner />
  ) : (
    <Container fluid className="main-content-container px-4">
      {/* Page Header */}
      <div className="page-header py-4">
        <Card small className="card-post card-post--1">
          <CardBody>
            <h1 className="text-primary">
              Formulas
              <Link
                to="/formulas/add"
                className="btn btn-primary pull-right mb-2"
              >
                Add Formula
              </Link>
            </h1>
            <FormInput
              placeholder="Search by title"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              name="name"
              className="mb-3"
            />
            {formulasArray && formulasArray.length > 0 ? (
              <DataTable
                columns={columns}
                data={formulasArray}
                pagination={true}
                paginationPerPage={30}
                striped={true}
                pointerOnHover={true}
              />
            ) : (
              <h4>No formula found</h4>
            )}
            <div className="mt-3">
              <Alert />
            </div>
          </CardBody>
        </Card>
      </div>
    </Container>
  );
};

Formulas.propTypes = {
  auth: PropTypes.object.isRequired,
  getAllFormulas: PropTypes.func.isRequired,
  deleteFormula: PropTypes.func.isRequired,
  formula: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  formula: state.formula,
});

export default connect(mapStateToProps, { getAllFormulas, deleteFormula })(
  Formulas
);
