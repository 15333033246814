import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { login } from "../../actions/auth";
import { Formik } from "formik";
import * as yup from "yup";
import {
  Container,
  Card,
  CardBody,
  FormInput,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "shards-react";
import Alert from "../../components/layout/Alert";

const validationSchema = yup.object({
  email: yup.string().required().email().label("*E-mail"),
  password: yup.string().required().min(6).label("*Password"),
});

function Login({ login, isAuthenticated }) {
  const [showPassword, togglePassword] = useState(false);
  const [focusedInput, setFocusedInput] = useState();

  // Redirect if logged in
  if (isAuthenticated) {
    return <Redirect to="/dashboard" />;
  }
  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
      }}
      onSubmit={(values) => {
        login(values.email, values.password);
      }}
      validationSchema={validationSchema}
    >
      {(formikProps) => (
        <Container fluid className="main-content-container px-4">
          {/* Page Header */}
          <div className="page-header py-4 text-center">
            <Card small className="card-post card-post--1 login-card">
              <CardBody>
                <Alert />
                <h1 className="large text-primary card-title">Sign In</h1>
                <p className="">
                  <i className="fas fa-user"></i> Sign Into Your Account
                </p>
                <form className="form col col-12">
                  <FormGroup>
                    <InputGroup className="mb-3">
                      <InputGroupAddon type="prepend">
                        <InputGroupText>
                          <i className="fa fa-user-secret" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <FormInput
                        onFocus={(e) => setFocusedInput(e.target.name)}
                        invalid={
                          formikProps.errors && formikProps.errors.email
                            ? true
                            : false
                        }
                        placeholder="Email Address"
                        value={formikProps.values.email}
                        onChange={formikProps.handleChange}
                        name="email"
                      />
                    </InputGroup>
                    <small className="text-danger">
                      {focusedInput === "email" && formikProps.errors.email}
                    </small>
                  </FormGroup>
                  <FormGroup>
                    <InputGroup className="mb-3">
                      <InputGroupAddon type="prepend">
                        <InputGroupText>
                          {/* eslint-disable-next-line */}
                          <a onClick={() => togglePassword(!showPassword)}>
                            <i
                              className={`fa fa-eye${
                                showPassword ? "-slash" : ""
                              }`}
                            />
                          </a>
                        </InputGroupText>
                      </InputGroupAddon>
                      <FormInput
                        onFocus={(e) => setFocusedInput(e.target.name)}
                        invalid={
                          formikProps.errors && formikProps.errors.password
                            ? true
                            : false
                        }
                        type={showPassword ? "text" : "password"}
                        placeholder="Password"
                        name="password"
                        minLength="6"
                        value={formikProps.values.password}
                        onChange={formikProps.handleChange}
                      />
                    </InputGroup>
                    <small className="text-danger">
                      {focusedInput === "password" &&
                        formikProps.errors.password}
                    </small>
                  </FormGroup>

                  <input
                    disabled={!formikProps.isValid}
                    type="submit"
                    className="bg-primary text-white text-center rounded btn btn-primary"
                    value="Login"
                    onClick={formikProps.handleSubmit}
                  />
                  <div className="mt-3 text-info">
                    <small>
                      Please contact to administrator in order to get your
                      credentials.
                    </small>
                  </div>
                </form>
              </CardBody>
            </Card>
          </div>
        </Container>
      )}
    </Formik>
  );
}
Login.propTypes = {
  login: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});
export default connect(mapStateToProps, { login })(Login);
