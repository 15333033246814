import axios from "axios";
import { setAlert } from "./alert";
import { searchProperties } from "./property";
import {
  ACCOUNT_ERROR,
  CREATE_ACCOUNT,
  DELETE_ACCOUNT,
  GET_ALL_ACCOUNTS,
  EDIT_ACCOUNT,
  FILTER_ACCOUNTS,
  GET_ACCOUNT_BY_ID,
} from "./types";

// Get all  account
export const getAllAccounts = () => async (dispatch) => {
  try {
    const res = await axios.get(`/api/accounts`);
    dispatch({
      type: GET_ALL_ACCOUNTS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: ACCOUNT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

// Get account by ID
export const getAccountById = (params) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/accounts/${params.accountId}`);
    dispatch({
      type: GET_ACCOUNT_BY_ID,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: ACCOUNT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

// Filter accounts
export const filterAccounts = (params) => async (dispatch) => {
  try {
    const res = await axios.post(`/api/accounts/filter`, params);
    dispatch({
      type: FILTER_ACCOUNTS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: ACCOUNT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

// Create account
export const createAccount = (formData) => async (dispatch) => {
  try {
    const res = await axios.post(`/api/accounts`, formData);

    dispatch({
      type: CREATE_ACCOUNT,
      payload: res.data,
    });

    dispatch(setAlert("Account Created", "success"));
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
    }
    dispatch({
      type: ACCOUNT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

// Edit account
export const editAccount = (formData, id) => async (dispatch) => {
  try {
    const res = await axios.put(`/api/accounts/edit/${id}`, formData);

    dispatch({
      type: EDIT_ACCOUNT,
      payload: res.data,
    });

    dispatch(setAlert("Account Updated", "success"));
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
    }
    dispatch({
      type: ACCOUNT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

// Delete account
export const deleteAccount = (id, reloadAccounts = false) => async (
  dispatch
) => {
  try {
    const res = await axios.delete(`/api/accounts/${id}`);

    dispatch({
      type: DELETE_ACCOUNT,
      payload: res.data,
    });
    dispatch(searchProperties(""));
    reloadAccounts && dispatch(getAllAccounts());

    dispatch(setAlert("Account Deleted", "success"));
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
    }
    dispatch({
      type: ACCOUNT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};
