// Alert
export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";

// Register
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";

// AUTH
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const ACCOUNT_DELETED = "ACCOUNT_DELETED";
export const CLEAR_PROFILE = "CLEAR_PROFILE";

// LOGIN
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";

// Logout
export const LOGOUT = "LOGOUT";

// USER
export const GET_ALL_USERS = "GET_ALL_USERS";
export const GET_ALL_USERS_FAILED = "GET_ALL_USERS_FAILED";
export const DELETE_USER = "DELETE_USER";
export const USER_ERROR = "USER_ERROR";
export const ADD_USER = "ADD_USER";
export const GET_USER = "GET_USER";
export const GET_ALL_UNFILTERED_USERS = "GET_ALL_UNFILTERED_USERS";

// CONTACT
export const GET_CONTACTS = "GET_CONTACTS";
export const GET_ALL_CONTACTS = "GET_ALL_CONTACTS";
export const GET_CONTACTS_FAILED = "GET_CONTACTS_FAILED";
export const DELETE_CONTACT = "DELETE_CONTACT";
export const CONTACT_ERROR = "CONTACT_ERROR";
export const ADD_CONTACT = "ADD_CONTACT";
export const GET_CONTACT = "GET_CONTACT";

// BRANCHES
export const GET_BRANCHES = "GET_BRANCHES";
export const GET_ALL_BRANCHES = "GET_ALL_BRANCHES";
export const GET_BRANCHES_FAILED = "GET_BRANCHES_FAILED";
export const DELETE_BRANCH = "DELETE_BRANCH";
export const BRANCH_ERROR = "BRANCH_ERROR";
export const ADD_BRANCH = "ADD_BRANCH";
export const GET_BRANCH = "GET_BRANCH";

// FORMULAS
export const GET_FORMULAS = "GET_FORMULAS";
export const GET_FORMULAS_FAILED = "GET_FORMULAS_FAILED";
export const DELETE_FORMULA = "DELETE_FORMULA";
export const FORMULA_ERROR = "FORMULA_ERROR";
export const ADD_FORMULA = "ADD_FORMULA";
export const GET_FORMULA = "GET_FORMULA";
export const GET_ALL_UNFILTERED_FORMULAS = "GET_ALL_UNFILTERED_FORMULAS";

// PROPERTIES
export const ADD_PROPERTY = "ADD_PROPERTY";
export const EDIT_PROPERTY = "EDIT_PROPERTY";
export const GET_ALL_PROPERTIES = "GET_ALL_PROPERTIES";
export const GET_PROPERTY_BY_ID = "GET_PROPERTY_BY_ID";
export const SEARCH_PROPERTY = "SEARCH_PROPERTY";
export const PROPERTY_ERROR = "PROPERTY_ERROR";
export const DELETE_PROPERTY = "DELETE_PROPERTY";

// Accounts
export const GET_ALL_ACCOUNTS = "GET_ALL_ACCOUNTS";
export const FILTER_ACCOUNTS = "FILTER_ACCOUNTS";
export const CREATE_ACCOUNT = "CREATE_ACCOUNT";
export const EDIT_ACCOUNT = "EDIT_ACCOUNT";
export const DELETE_ACCOUNT = "DELETE_ACCOUNT";
export const GET_ACCOUNT_BY_ID = "GET_ACCOUNT_BY_ID";
export const ACCOUNT_ERROR = "ACCOUNT_ERROR";

// Deductions
export const GET_ALL_DEDUCTIONS = "GET_ALL_DEDUCTIONS";
export const FILTER_DEDUCTIONS = "FILTER_DEDUCTIONS";
export const CREATE_DEDUCTION = "CREATE_DEDUCTION";
export const EDIT_DEDUCTION = "EDIT_DEDUCTION";
export const DELETE_DEDUCTION = "DELETE_DEDUCTION";
export const GET_DEDUCTION_BY_ID = "GET_DEDUCTION_BY_ID";
export const DEDUCTION_ERROR = "DEDUCTION_ERROR";
