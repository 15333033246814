import {
  ADD_PROPERTY,
  PROPERTY_ERROR,
  GET_ALL_PROPERTIES,
  SEARCH_PROPERTY,
  DELETE_PROPERTY,
  EDIT_PROPERTY,
  GET_PROPERTY_BY_ID,
} from "../actions/types";

const initialState = {
  error: {},
  loading: true,
  properties: [],
  property: {},
};

export default function Property(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case ADD_PROPERTY:
      return {
        ...state,
        property: {},
        loading: false,
      };

    case GET_PROPERTY_BY_ID:
      return {
        ...state,
        property: payload,
        loading: false,
      };

    case GET_ALL_PROPERTIES:
    case SEARCH_PROPERTY:
      return {
        ...state,
        properties: payload,
        property: {},
        loading: false,
      };

    case EDIT_PROPERTY:
      return {
        ...state,
        loading: false,
      };

    case DELETE_PROPERTY:
      return {
        ...state,
        properties: state.properties.filter(
          (property) => property.id !== payload
        ),
        property: {},
        loading: false,
      };

    case PROPERTY_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };

    default:
      return state;
  }
}
