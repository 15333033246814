import React from "react";
import axios from "axios";
import { setAlert } from "./alert";
import {
  GET_CONTACTS,
  GET_CONTACTS_FAILED,
  DELETE_CONTACT,
  CONTACT_ERROR,
  ADD_CONTACT,
  GET_CONTACT,
  GET_ALL_CONTACTS,
} from "./types";

// GET All Contacts
export const getAllContactsFromDb = () => async (dispatch) => {
  try {
    const res = await axios.get(`/api/contacts`);

    dispatch({
      type: GET_ALL_CONTACTS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_CONTACTS_FAILED,
    });
  }
};

// GET Contacts
export const getAllContacts = (search) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/contacts/search/${search}`);

    dispatch({
      type: GET_CONTACTS,
      payload: res.data,
    });
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
    }
    dispatch({
      type: GET_CONTACTS_FAILED,
    });
  }
};

export const MemoizedGetAllContacts = React.memo(getAllContacts);

// Delete Contact
export const deleteContact = (id) => async (dispatch) => {
  try {
    await axios.delete(`/api/contacts/${id}`);

    dispatch({
      type: DELETE_CONTACT,
      payload: id,
    });

    dispatch(setAlert("Contact Removed", "success"));
  } catch (err) {
    console.log(err);
    dispatch({
      type: CONTACT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

// Add contact
export const addContact = (formData) => async (dispatch) => {
  try {
    let res = null;

    if (formData.id) {
      res = await axios.put(`/api/contacts/edit/${formData.id}`, formData);
    } else {
      res = await axios.post("/api/contacts/", formData);
    }
    dispatch({
      type: ADD_CONTACT,
      payload: res.data,
    });

    dispatch(
      setAlert(formData.id ? "Contact updated" : "Contact created", "success")
    );
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
      //dispatch(setAlert(errors.map(error => `${error.msg}`), "danger"));
    }
    dispatch({
      type: CONTACT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

// Get contact by id
export const getContactById = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/contacts/${id}`);

    dispatch({
      type: GET_CONTACT,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: CONTACT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};
