import React, { Fragment, useEffect } from "react";
import { connect } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import Alert from "../layout/Alert";
import { Card, CardBody, Container } from "shards-react";
import { getAccountById } from "../../actions/account";

function ViewAccount({ getAccountById, account: { loading, viewAccount } }) {
  const location = useLocation();
  const {
    state: { accountId },
  } = location;

  useEffect(() => {
    const params = {
      accountId,
    };
    getAccountById(params);
  }, [accountId, getAccountById]);

  const {
    property_sale_value,
    commission,
    overspending_cost,
    referral_share_amount,
    Branch,
    branch_share_amount,
    office_commission_amount,
    gross_commission_excluding_gst,
    admin_cost,
    branding_share_amount,
    internal_commission_amount,
    AccountAgents,
  } = viewAccount;
  console.log(viewAccount);

  const addComma = (value)=>{
      let parts = value.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return parts.join(".");
  }
  return (
    !loading && (
      <Container fluid className="main-content-container px-4">
        {/* Page Header */}
        <div className="page-header py-4">
          <Card small className="card-post card-post--1">
            <CardBody>
              <Alert />
              <h1 className="large text-primary ">
                {viewAccount && viewAccount.id
                  ? `${
                      viewAccount.Property && viewAccount.Property.title
                    }'s Account`
                  : ""}
              </h1>
              <p>
                <strong>Amount:</strong>{" "}
                {property_sale_value && addComma(property_sale_value)} <br />
                <strong>Commission Cost:</strong>{" "}
                {addComma((commission / 100) * property_sale_value)} <br />
                <strong>Overspending Cost:</strong>{" "}
                {overspending_cost && addComma(overspending_cost)} <br />
                <strong>Referral Cost Amount:</strong>{" "}
                {referral_share_amount && addComma(referral_share_amount)} <br />
                <strong>Branch:</strong> {Branch && Branch.title} <br />
                <strong>Branch Share Amount:</strong>{" "}
                {branch_share_amount && addComma(branch_share_amount)} <br />
                <strong>Office Commission Amount:</strong>{" "}
                {office_commission_amount && addComma(office_commission_amount)} <br />
                <strong>Office Commission Exc. GST:</strong>{" "}
                {gross_commission_excluding_gst &&
                  addComma(gross_commission_excluding_gst)}{" "}
                <br />
                <strong>Admin Cost:</strong> {admin_cost && addComma(admin_cost)} <br />
                <strong>Branding Cost Amount:</strong>{" "}
                {branding_share_amount && addComma(branding_share_amount)} <br />
                <strong>Internal Cost Amount:</strong>{" "}
                {internal_commission_amount && addComma(internal_commission_amount)}{" "}
                <br />
                {AccountAgents &&
                  AccountAgents.length > 0 &&
                  AccountAgents.map((a, index) => (
                    <Fragment key={index}>
                      <strong>{`Agent ${
                        a && a.User && a.User.name
                      } Share Amount:`}</strong>{" "}
                      {addComma(a.agent_commission_amount)} <br />
                    </Fragment>
                  ))}
              </p>
              <Link to="/properties" className="btn btn-secondary">
                Back
              </Link>{" "}
              <Link to={`/accounts/edit/${accountId}`} className="lead">
                Click here to Edit
              </Link>
            </CardBody>
          </Card>
        </div>
      </Container>
    )
  );
}

ViewAccount.propTypes = {
  getAccountById: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};
const mapStateToProps = (state) => ({
  account: state.account,
});

export default connect(mapStateToProps, { getAccountById })(ViewAccount);
