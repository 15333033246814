import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { addFormula, getFormulaById } from "../../actions/formula";
import Alert from "../layout/Alert";
import { Formik } from "formik";
import * as yup from "yup";
import { setAlert } from "../../actions/alert";
import {
  Card,
  CardBody,
  FormInput,
  FormGroup,
  InputGroup,
  Container,
  InputGroupAddon,
  FormSelect,
  InputGroupText,
} from "shards-react";

const validationSchema = yup.object({
  title: yup.string().required().label("*Title"),
});

function AddFormula({
  history,
  setAlert,
  getFormulaById,
  addFormula,
  formula: { formula },
  match: {
    params: { id },
  },
}) {
  const [focusedInput, setFocusedInput] = useState();
  const [fetchFromApi, setFetchFromApi] = useState(true);
  const [formData, setFormData] = useState({
    title: "",
    commission: "",
    commission_type: "percentage",
    referral_share: "",
    referral_share_type: "percentage",
    branch_share: "",
    branch_share_type: "percentage",
    office_commission: "",
    office_commission_type: "percentage",
    internal_commission: "",
    internal_commission_type: "percentage",
    branding_share: "",
    branding_share_type: "percentage",
    agents: [
      {
        agent_commission: "",
        agent_commission_type: "percentage",
      },
    ],
  });

  useEffect(() => {
    if (id && fetchFromApi) {
      if (Object.keys(formula).length === 0 && formula.constructor === Object) {
        getFormulaById(id);
      } else {
        setFormData({
          title: formula.title,
          commission: formula.commission,
          commission_type: formula.commission_type,
          referral_share: formula.referral_share,
          referral_share_type: formula.referral_share_type,
          branch_share: formula.branch_share,
          branch_share_type: formula.branch_share_type,
          office_commission: formula.office_commission,
          office_commission_type: formula.office_commission_type,
          internal_commission: formula.internal_commission,
          internal_commission_type: formula.internal_commission_type,
          branding_share: formula.branding_share,
          branding_share_type: formula.branding_share_type,
          agents: formula.FormulaAgents.length
            ? formula.FormulaAgents
            : [
                {
                  agent_commission: "",
                  agent_commission_type: "percentage",
                },
              ],
        });
      }
    }
  }, [id, getFormulaById, formula, fetchFromApi]);

  const {
    title,
    commission,
    commission_type,
    referral_share,
    referral_share_type,
    branch_share,
    branch_share_type,
    office_commission,
    office_commission_type,
    internal_commission,
    internal_commission_type,
    branding_share,
    branding_share_type,
  } = formData;

  let { agents } = formData;

  const addAgent = () => {
    agents.push({
      agent_commission: "",
      agent_commission_type: "percentage",
    });
    setFormData({
      ...formData,
      agents,
    });
  };

  const removeAgent = (index) => {
    agents.splice(index, 1);
    setFormData({
      ...formData,
      agents,
    });
  };

  const onChangeHandler = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onAgentTypeChangeHandler = (index, e) => {
    agents[index] = { ...agents[index], agent_commission_type: e.target.value };
    setFormData({
      ...formData,
      agents,
    });
  };
  const onAgentComissionChangeHandler = async (index, e) => {
    agents[index] = { ...agents[index], agent_commission: e.target.value };
    setFormData({
      ...formData,
      agents,
    });
  };
  const submitForm = async () => {
    setFetchFromApi(false);

    addFormula(
      {
        title,
        commission: commission ? commission : 0,
        commission_type,
        referral_share: referral_share ? referral_share : 0,
        referral_share_type,
        branch_share: branch_share ? branch_share : 0,
        branch_share_type,
        office_commission: office_commission ? office_commission : 0,
        office_commission_type,
        internal_commission: internal_commission ? internal_commission : 0,
        internal_commission_type,
        branding_share: branding_share ? branding_share : 0,
        branding_share_type,
        agents,
        id,
      },
      history
    );
  };

  return (
    <Formik
      enableReinitialize
      validationSchema={validationSchema}
      initialValues={{
        title: title,
        gross_commission: commission,
        referral_share: referral_share,
        branch_share: branch_share,
        office_commission: office_commission,
        internal_commission: internal_commission,
        branding_share: branding_share,
      }}
      onSubmit={(values) => {
        let agentsSum = 0;

        agentsSum =
          agents &&
          agents.length &&
          agents
            .map((item) => {
              return item.agent_commission !== ""
                ? parseFloat(item.agent_commission)
                : 0;
            })
            .reduce((prev, next) => prev + next);

        if (parseFloat(branch_share) + parseFloat(office_commission) !== 100) {
          setAlert(
            "The sum of Branch share and office commission should exactly be 100%",
            "danger"
          );
        } else if (
          parseFloat(branding_share) +
            parseFloat(internal_commission) +
            parseFloat(agentsSum) !==
          100
        ) {
          setAlert(
            "The sum of Branding, Internal and Agents commission should exactly be 100%",
            "danger"
          );
        } else {
          submitForm();
        }
      }}
    >
      {(formikProps) => (
        <Container fluid className="main-content-container px-4">
          {/* Page Header */}
          <div className="page-header py-4">
            <Card small className="card-post card-post---1">
              <CardBody>
                <h1 className="large text-primary">
                  {id ? "Edit" : "Add"} Formula
                </h1>
                <p className="">{id ? "Update" : "Create"} formula details</p>
                <form className="form col col-lg-6 col-md-12">
                  <FormGroup>
                    <label>Title</label>
                    <InputGroup className="mb-3">
                      <FormInput
                        onFocus={(e) => setFocusedInput(e.target.name)}
                        invalid={
                          formikProps.errors && formikProps.errors.title
                            ? true
                            : false
                        }
                        placeholder="Title"
                        value={title}
                        onChange={onChangeHandler}
                        name="title"
                      />
                    </InputGroup>
                    <small className="text-danger">
                      {focusedInput === "title" && formikProps.errors.title}
                    </small>
                  </FormGroup>
                  <FormGroup>
                    <label>Gross Commission</label>
                    <InputGroup className="mb-3">
                      <InputGroupAddon type="prepend">
                        <FormSelect
                          value={commission_type}
                          onChange={onChangeHandler}
                          name="commission_type"
                        >
                          <option value="percentage">%</option>
                          <option value="amount">$</option>
                        </FormSelect>
                      </InputGroupAddon>
                      <FormInput
                        onFocus={(e) => setFocusedInput(e.target.name)}
                        invalid={
                          formikProps.errors &&
                          formikProps.errors.gross_commission
                            ? true
                            : false
                        }
                        placeholder="Commission"
                        value={commission}
                        onChange={onChangeHandler}
                        name="commission"
                        type="number"
                      />
                    </InputGroup>
                    <small className="text-danger">
                      {focusedInput === "commission" &&
                        formikProps.errors.gross_commission}
                    </small>
                  </FormGroup>
                  <FormGroup>
                    <label>Referral Share</label>
                    <InputGroup className="mb-3">
                      <InputGroupAddon type="prepend">
                        <FormSelect
                          value={referral_share_type}
                          onChange={onChangeHandler}
                          name="referral_share_type"
                        >
                          <option value="percentage">%</option>
                          <option value="amount">$</option>
                        </FormSelect>
                      </InputGroupAddon>
                      <FormInput
                        onFocus={(e) => setFocusedInput(e.target.name)}
                        invalid={
                          formikProps.errors &&
                          formikProps.errors.referral_share
                            ? true
                            : false
                        }
                        placeholder="Referral Share"
                        value={referral_share}
                        onChange={onChangeHandler}
                        name="referral_share"
                        type="number"
                      />
                    </InputGroup>
                    <small className="text-danger">
                      {focusedInput === "referral_share" &&
                        formikProps.errors.referral_share}
                    </small>
                  </FormGroup>
                  <FormGroup>
                    <label>Branch share</label>
                    <InputGroup className="mb-3">
                      <InputGroupAddon type="prepend">
                        <FormSelect
                          value={branch_share_type}
                          onChange={onChangeHandler}
                          name="branch_share_type"
                        >
                          <option value="percentage">%</option>
                          <option value="amount">$</option>
                        </FormSelect>
                      </InputGroupAddon>
                      <FormInput
                        onFocus={(e) => setFocusedInput(e.target.name)}
                        invalid={
                          formikProps.errors && formikProps.errors.branch_share
                            ? true
                            : false
                        }
                        placeholder="Branch Share"
                        value={branch_share}
                        onChange={onChangeHandler}
                        name="branch_share"
                        type="number"
                      />
                    </InputGroup>
                    <small className="text-danger">
                      {focusedInput === "branch_share" &&
                        formikProps.errors.branch_share}
                    </small>
                  </FormGroup>
                  <FormGroup>
                    <label>Office Commission</label>
                    <InputGroup className="mb-3">
                      <InputGroupAddon type="prepend">
                        <FormSelect
                          value={office_commission_type}
                          onChange={onChangeHandler}
                          name="office_commission_type"
                        >
                          <option value="percentage">%</option>
                          <option value="amount">$</option>
                        </FormSelect>
                      </InputGroupAddon>
                      <FormInput
                        onFocus={(e) => setFocusedInput(e.target.name)}
                        invalid={
                          formikProps.errors &&
                          formikProps.errors.office_commission
                            ? true
                            : false
                        }
                        placeholder="Office Commission"
                        value={office_commission}
                        onChange={onChangeHandler}
                        name="office_commission"
                        type="number"
                      />
                    </InputGroup>
                    <small className="text-danger">
                      {focusedInput === "office_commission" &&
                        formikProps.errors.office_commission}
                    </small>
                  </FormGroup>
                  <FormGroup>
                    <label>Internal Commission</label>
                    <InputGroup className="mb-3">
                      <InputGroupAddon type="prepend">
                        <FormSelect
                          value={internal_commission_type}
                          onChange={onChangeHandler}
                          name="internal_commission_type"
                        >
                          <option value="percentage">%</option>
                          <option value="amount">$</option>
                        </FormSelect>
                      </InputGroupAddon>
                      <FormInput
                        onFocus={(e) => setFocusedInput(e.target.name)}
                        invalid={
                          formikProps.errors &&
                          formikProps.errors.internal_commission
                            ? true
                            : false
                        }
                        placeholder="Internal Commission"
                        value={internal_commission}
                        onChange={onChangeHandler}
                        name="internal_commission"
                        type="number"
                      />
                    </InputGroup>
                    <small className="text-danger">
                      {focusedInput === "internal_commission" &&
                        formikProps.errors.internal_commission}
                    </small>
                  </FormGroup>
                  <FormGroup>
                    <label>Branding Fees</label>
                    <InputGroup className="mb-3">
                      <InputGroupAddon type="prepend">
                        <FormSelect
                          value={branding_share_type}
                          onChange={onChangeHandler}
                          name="branding_share_type"
                        >
                          <option value="percentage">%</option>
                          <option value="amount">$</option>
                        </FormSelect>
                      </InputGroupAddon>
                      <FormInput
                        onFocus={(e) => setFocusedInput(e.target.name)}
                        invalid={
                          formikProps.errors &&
                          formikProps.errors.branding_share
                            ? true
                            : false
                        }
                        placeholder="Branding Fees"
                        value={branding_share}
                        onChange={onChangeHandler}
                        name="branding_share"
                        type="number"
                      />
                    </InputGroup>
                    <small className="text-danger">
                      {focusedInput === "branding_share" &&
                        formikProps.errors.branding_share}
                    </small>
                  </FormGroup>
                  <FormGroup>
                    <label>Agent Share</label>
                    <InputGroup className="mb-3">
                      <InputGroupAddon type="prepend">
                        <FormSelect
                          value={agents[0].agent_commission_type}
                          onChange={(e) => onAgentTypeChangeHandler(0, e)}
                        >
                          <option value="percentage">%</option>
                          <option value="amount">$</option>
                        </FormSelect>
                      </InputGroupAddon>
                      <FormInput
                        placeholder="Agent share"
                        value={agents[0].agent_commission}
                        onChange={(e) => onAgentComissionChangeHandler(0, e)}
                        type="number"
                      />
                      <InputGroupAddon type="append">
                        <InputGroupText>
                          <i className="fa fa-plus" onClick={addAgent}></i>
                        </InputGroupText>
                      </InputGroupAddon>
                    </InputGroup>
                  </FormGroup>
                  {agents.length > 1 &&
                    agents.map((agent, index) => {
                      return index === 0 ? (
                        <Fragment></Fragment>
                      ) : (
                        <FormGroup>
                          <label>Agent Share</label>
                          <InputGroup className="mb-3">
                            <InputGroupAddon type="prepend">
                              <FormSelect
                                value={agents[index].agent_commission_type}
                                onChange={(e) =>
                                  onAgentTypeChangeHandler(index, e)
                                }
                              >
                                <option value="percentage">%</option>
                                <option value="amount">$</option>
                              </FormSelect>
                            </InputGroupAddon>
                            <FormInput
                              placeholder="Agent share"
                              value={agents[index].agent_commission}
                              onChange={(e) =>
                                onAgentComissionChangeHandler(index, e)
                              }
                              type="number"
                            />
                            <InputGroupAddon type="append">
                              <InputGroupText>
                                <i
                                  className="fa fa-trash"
                                  onClick={() => removeAgent(index)}
                                ></i>
                              </InputGroupText>
                            </InputGroupAddon>
                          </InputGroup>
                        </FormGroup>
                      );
                    })}
                  <input
                    type="submit"
                    className="bg-primary text-white text-center rounded btn btn-primary"
                    value="Save"
                    onClick={formikProps.handleSubmit}
                    disabled={!formikProps.isValid}
                  />{" "}
                  <Link to="/formulas" className="btn btn-secondary">
                    Back
                  </Link>
                </form>
                <div className="mt-3">
                  <Alert />
                </div>
              </CardBody>
            </Card>
          </div>
        </Container>
      )}
    </Formik>
  );
}

AddFormula.propTypes = {
  getFormulaById: PropTypes.func.isRequired,
  addFormula: PropTypes.func.isRequired,
  formula: PropTypes.object,
  setAlert: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  formula: state.formula,
});

export default connect(mapStateToProps, {
  getFormulaById,
  addFormula,
  setAlert,
})(AddFormula);
