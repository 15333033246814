import { combineReducers } from "redux";
import alert from "./alert";
import auth from "./auth";
import staff from "./staff";
import contact from "./contact";
import branch from "./branch";
import formula from "./formula";
import property from "./property";
import account from "./account";
import deduction from "./deduction";
export default combineReducers({
  alert,
  auth,
  staff,
  contact,
  branch,
  formula,
  property,
  account,
  deduction,
});
