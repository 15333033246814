import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Container, Card, CardBody, FormInput } from "shards-react";
import { Link, Redirect } from "react-router-dom";
import Spinner from "../layout/Spinner";
import Alert from "../layout/Alert";
import DataTable from "react-data-table-component";
import PropertyItem from "./PropertyItem";
import { searchProperties } from "../../actions/property";
import Moment from "react-moment";
import { CSVLink } from "react-csv";
import { currencyFormatter } from "../../utils/helper";

const Properties = ({
  auth: { isAuthenticated, user },
  loading,
  properties,
  searchProperties,
}) => {
  const [propertiesArray, setPropertiesArray] = useState([]);
  const [exportList, setExportList] = useState(null);
  useEffect(() => {
    let prop = [];

    prop =
      properties && properties.length > 0
        ? properties.map((c, index) => ({ ...c, frontEndIndex: index + 1 }))
        : [];
    setPropertiesArray(prop);
    // for export
    if (
      properties &&
      properties.length > 0 &&
      user &&
      user.role === "Admin" &&
      properties.filter((item) => item.Account).length > 0
    ) {
      let arr = [
        [
          "Property",
          "Amount",
          "Commission Cost",
          "Overspending Cost",
          "Referral Cost Amount",
          "Branch Share Amount",
          "Office Commission Amount",
          "Office Commission Exc. GST",
          "Admin Cost",
          "Branding Cost",
          "Internal Cost",
          "Agents",
          "Total",
        ],
      ];

      let agentDetails = "";
      let totalAmount = 0,
        totalCommissionCost = 0,
        totalOverspendingCost = 0,
        totalReferralCost = 0,
        totalBranchShareAmount = 0,
        totalOfficeCommissionAmount = 0,
        totalOfficeCommissionAmountGst = 0,
        totalAdminCost = 0,
        totalBrandingCost = 0,
        totalInternalCost = 0,
        totalAgentCost = 0;

      let agentCostPerRow = 0;
      let agentDetailList = [];
      properties
        .filter((item) => item.Account)
        .map((item) => {
          agentCostPerRow = 0;
          agentDetailList = [];
          item.Account.AccountAgents &&
            item.Account.AccountAgents.map((aa) => {
              agentCostPerRow +=
                aa.User.gst !== ""
                  ? aa.agent_commission_amount +
                    (aa.agent_commission_amount / 10) * 1
                  : aa.agent_commission_amount * 1;
              agentDetailList.push(
                aa.User.name +
                  "- " +
                  currencyFormatter.format(
                    aa.User.gst !== ""
                      ? aa.agent_commission_amount +
                          (aa.agent_commission_amount / 10) * 1
                      : aa.agent_commission_amount * 1
                  )
              );
              totalAgentCost += parseFloat(
                aa.User.gst !== ""
                  ? aa.agent_commission_amount +
                      (aa.agent_commission_amount / 10) * 1
                  : aa.agent_commission_amount * 1
              );
            });
          agentDetails = agentDetailList.join(" / ");

          arr.push([
            item.title,
            currencyFormatter.format(item.Account.property_sale_value),
            item.Account.commission
              ? currencyFormatter.format(
                  parseFloat(item.Account.property_sale_value) *
                    (item.Account.commission / 100)
                )
              : 0,
            item.Account.overspending_cost
              ? currencyFormatter.format(item.Account.overspending_cost)
              : 0,
            item.Account.referral_share_amount
              ? currencyFormatter.format(item.Account.referral_share_amount)
              : 0,
            item.Account.branch_share_amount
              ? currencyFormatter.format(item.Account.branch_share_amount)
              : 0,
            item.Account.office_commission_amount
              ? currencyFormatter.format(item.Account.office_commission_amount)
              : 0,
            item.Account.gross_commission_excluding_gst
              ? currencyFormatter.format(
                  item.Account.gross_commission_excluding_gst
                )
              : 0,
            item.Account.admin_cost
              ? currencyFormatter.format(item.Account.admin_cost)
              : 0,
            item.Account.branding_share_amount
              ? currencyFormatter.format(item.Account.branding_share_amount)
              : 0,
            item.Account.internal_commission_amount
              ? currencyFormatter.format(
                  item.Account.internal_commission_amount
                )
              : 0,
            agentDetails,
            currencyFormatter.format(
              parseFloat(
                item.Account.property_sale_value
                  ? parseFloat(item.Account.property_sale_value)
                  : 0
              ) +
                parseFloat(
                  item.Account.commission
                    ? parseFloat(
                        parseFloat(item.Account.property_sale_value) *
                          (item.Account.commission / 100)
                      )
                    : 0
                ) +
                parseFloat(
                  item.Account.overspending_cost
                    ? parseFloat(item.Account.overspending_cost)
                    : 0
                ) +
                parseFloat(
                  item.Account.referral_share_amount
                    ? parseFloat(item.Account.referral_share_amount)
                    : 0
                ) +
                parseFloat(
                  item.Account.branch_share_amount
                    ? parseFloat(item.Account.branch_share_amount)
                    : 0
                ) +
                parseFloat(
                  item.Account.office_commission_amount
                    ? parseFloat(item.Account.office_commission_amount)
                    : 0
                ) +
                parseFloat(
                  item.Account.gross_commission_excluding_gst
                    ? parseFloat(item.Account.gross_commission_excluding_gst)
                    : 0
                ) +
                parseFloat(
                  item.Account.admin_cost
                    ? parseFloat(item.Account.admin_cost)
                    : 0
                ) +
                parseFloat(
                  item.Account.internal_commission_amount
                    ? parseFloat(item.Account.internal_commission_amount)
                    : 0
                ) +
                parseFloat(
                  item.Account.internal_commission_amount
                    ? parseFloat(item.Account.internal_commission_amount)
                    : 0
                ) +
                parseFloat(agentCostPerRow)
            ),
          ]);

          totalAmount += item.Account.property_sale_value
            ? parseFloat(item.Account.property_sale_value)
            : 0;
          totalCommissionCost += item.Account.commission
            ? parseFloat(
                parseFloat(item.Account.property_sale_value) *
                  (item.Account.commission / 100)
              )
            : 0;
          totalOverspendingCost += item.Account.overspending_cost
            ? parseFloat(item.Account.overspending_cost)
            : 0;
          totalReferralCost += item.Account.referral_share_amount
            ? parseFloat(item.Account.referral_share_amount)
            : 0;
          totalBranchShareAmount += item.Account.branch_share_amount
            ? parseFloat(item.Account.branch_share_amount)
            : 0;
          totalOfficeCommissionAmount += item.Account.office_commission_amount
            ? parseFloat(item.Account.office_commission_amount)
            : 0;
          totalOfficeCommissionAmountGst += item.Account
            .gross_commission_excluding_gst
            ? parseFloat(item.Account.gross_commission_excluding_gst)
            : 0;
          totalAdminCost += item.Account.admin_cost
            ? parseFloat(item.Account.admin_cost)
            : 0;
          totalBrandingCost += item.Account.internal_commission_amount
            ? parseFloat(item.Account.internal_commission_amount)
            : 0;
          totalInternalCost += item.Account.internal_commission_amount
            ? parseFloat(item.Account.internal_commission_amount)
            : 0;
        });
      arr.push(["", "", "", "", "", "", "", "", "", "", "", ""]);
      arr.push([
        "Total",
        currencyFormatter.format(totalAmount),
        currencyFormatter.format(totalCommissionCost),
        currencyFormatter.format(totalOverspendingCost),
        currencyFormatter.format(totalReferralCost),
        currencyFormatter.format(totalBranchShareAmount),
        currencyFormatter.format(totalOfficeCommissionAmount),
        currencyFormatter.format(totalOfficeCommissionAmountGst),
        currencyFormatter.format(totalAdminCost),
        currencyFormatter.format(totalBrandingCost),
        currencyFormatter.format(totalInternalCost),
        currencyFormatter.format(totalAgentCost),
        currencyFormatter.format(
          totalAmount +
            totalCommissionCost +
            totalOverspendingCost +
            totalReferralCost +
            totalBranchShareAmount +
            totalOfficeCommissionAmount +
            totalOfficeCommissionAmountGst +
            totalAdminCost +
            totalBrandingCost +
            totalInternalCost +
            totalAgentCost
        ),
      ]);
      setExportList(arr);
    } else {
      setExportList(null);
    }
  }, [properties]);

  const staffProperties =
    user &&
    user.role === "Agent" &&
    propertiesArray.filter(
      (item) => item.Account.AccountAgents[0].agent_id + "" === user.id + ""
    );

  const [searchText, setSearchText] = useState("");

  const columns = [
    {
      name: "#",
      selector: "frontEndIndex",
      sortable: true,
    },
    {
      name: "Title",
      selector: "title",
      sortable: true,
      cell: (row) => <Link to={`/properties/view/${row.id}`}>{row.title}</Link>,
    },
    {
      name: "Location",
      selector: "location",
      sortable: true,
    },
    {
      name: "Formula",
      selector: (row) =>
        row.Formula && row.Formula.title ? row.Formula.title : "",
      sortable: true,
    },
    {
      name: "Created On",
      selector: "createdAt",
      sortable: true,
      cell: (row) => <Moment format="Do MMM YYYY">{row.createdAt}</Moment>,
    },

    {
      name: "Action",
      // maxWidth: '600px', // when using custom you should use width or maxWidth, otherwise, the table will default to flex grow behavior
      cell: (row) => <PropertyItem key={row.id} property={row} />,
    },
    {
      cell: (row) =>
        row.Account === null ? (
          <Link
            to={`/properties/${row.id}/account`}
            className="btn btn-primary pull-right"
          >
            Make Sale
          </Link>
        ) : (
          <Link
            to={{
              pathname: `/accounts/view/${row.id}`,
              state: { accountId: row.Account.id },
            }}
            className="btn btn-primary pull-right"
          >
            View Sale
          </Link>
        ),
      right: true,
    },
  ];

  useEffect(() => {
    searchProperties(searchText);
  }, [searchText, searchProperties]);

  return !(isAuthenticated && user !== null) ? (
    <Redirect to={"/dashboard"} />
  ) : loading ? (
    <Spinner />
  ) : (
    <Container fluid className="main-content-container px-4">
      {/* Page Header */}
      <div className="page-header py-4">
        <Card small className="card-post card-post--1">
          <CardBody>
            <h1 className="text-primary">
              All Properties
              <Link
                to="/properties/add"
                className="btn btn-primary pull-right mb-2"
              >
                Add Property
              </Link>
              {user && user.role === "Admin" && exportList && (
                <CSVLink
                  data={exportList}
                  filename={`properties-${+new Date().getTime()}.csv`}
                  className="btn btn-info pull-right mr-2"
                >
                  Export
                </CSVLink>
              )}
            </h1>
            <FormInput
              placeholder="Search by property name or location"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              name="name"
              className="mb-3"
            />
            {propertiesArray && propertiesArray.length > 0 ? (
              <DataTable
                columns={columns}
                data={
                  user && user.role === "Admin"
                    ? propertiesArray
                    : staffProperties
                }
                pagination={true}
                paginationPerPage={30}
                striped={true}
                pointerOnHover={true}
              />
            ) : (
              <h4>No property found</h4>
            )}
            <div className="mt-3">
              <Alert />
            </div>
          </CardBody>
        </Card>
      </div>
    </Container>
  );
};

Properties.propTypes = {
  auth: PropTypes.object.isRequired,
  properties: PropTypes.array.isRequired,
  searchProperties: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  loading: state.property.loading,
  auth: state.auth,
  properties: state.property.properties,
});

export default connect(mapStateToProps, { searchProperties })(Properties);
