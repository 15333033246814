import {
  GET_ALL_USERS,
  GET_ALL_USERS_FAILED,
  DELETE_USER,
  USER_ERROR,
  ADD_USER,
  GET_USER,
  GET_ALL_UNFILTERED_USERS,
} from "../actions/types";

const initialState = {
  users: [],
  unfilteredUsers: [],
  loading: true,
  error: {},
  user: {},
};

export default function Staff(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_ALL_UNFILTERED_USERS:
      return {
        ...state,
        unfilteredUsers: payload,
        loading: false,
        user: {},
      };
    case GET_ALL_USERS:
      return {
        ...state,
        users: payload,
        loading: false,
        user: {},
      };
    case GET_ALL_USERS_FAILED:
      return {
        ...state,
        users: null,
        loading: false,
        user: {},
      };
    case DELETE_USER:
      return {
        ...state,
        users: state.users.filter((user) => user.id !== payload),
        loading: false,
        user: {},
      };
    case USER_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
        user: {},
      };
    case ADD_USER:
      return {
        ...state,
        users: [payload, ...state.users],
        loading: false,
        user: {},
      };
    case GET_USER:
      return {
        ...state,
        user: payload,
        loading: false,
      };
    default:
      return state;
  }
}
