// import React from "react";
// import { Redirect } from "react-router-dom";

// Layout Types
import { DefaultLayout } from "./layouts";

// Route Views
import Dashboard from "./views/Dashboard";

//users
import Users from "./components/users/Users";
import AddUser from "./components/users/AddUser";
import ViewUser from "./components/users/ViewUser";
import EditUserProfile from "./components/users/EditUserProfile";

// contacts
import Contacts from "./components/contacts/Contacts";
import AddContact from "./components/contacts/AddContact";
import ViewContact from "./components/contacts/ViewContact";

// branches
import Branches from "./components/branches/Branches";
import AddBranch from "./components/branches/AddBranch";

// formulas
import Formulas from "./components/formulas/Formulas";
import AddFormula from "./components/formulas/AddFormula";

// properties
import AddProperty from "./components/properties/AddProperty";
import Properties from "./components/properties/Properties";
import ViewProperty from "./components/properties/ViewProperty";

// accounts
import Accounts from "./components/accounts/Accounts";
import AccountsListing from "./components/accounts/AccountsListing";
import EditAccount from "./components/accounts/EditAccount";
import ViewAccount from "./components/accounts/ViewAccount";

// deductions
import Deductions from "./components/deductions/Deductions";
import AddDeductions from "./components/deductions/AddEdit";

const Routes = [
  {
    path: "/",
    exact: true,
    layout: DefaultLayout,
    component: Dashboard,
  },
  {
    path: "/dashboard",
    exact: true,
    layout: DefaultLayout,
    component: Dashboard,
  },
  {
    path: "/users",
    exact: true,
    layout: DefaultLayout,
    component: Users,
  },
  {
    path: "/users/add",
    exact: true,
    layout: DefaultLayout,
    component: AddUser,
  },
  {
    path: "/users/edit/:id",
    exact: true,
    layout: DefaultLayout,
    component: AddUser,
  },
  {
    path: "/users/view/:id",
    exact: true,
    layout: DefaultLayout,
    component: ViewUser,
  },
  {
    path: "/edit-user-profile",
    exact: true,
    layout: DefaultLayout,
    component: EditUserProfile,
  },

  {
    path: "/contacts",
    exact: true,
    layout: DefaultLayout,
    component: Contacts,
  },
  {
    path: "/contacts/add",
    exact: true,
    layout: DefaultLayout,
    component: AddContact,
  },
  {
    path: "/contacts/edit/:id",
    exact: true,
    layout: DefaultLayout,
    component: AddContact,
  },
  {
    path: "/contacts/view/:id",
    exact: true,
    layout: DefaultLayout,
    component: ViewContact,
  },

  {
    path: "/branches",
    exact: true,
    layout: DefaultLayout,
    component: Branches,
  },
  {
    path: "/branches/add",
    exact: true,
    layout: DefaultLayout,
    component: AddBranch,
  },
  {
    path: "/branches/edit/:id",
    exact: true,
    layout: DefaultLayout,
    component: AddBranch,
  },

  {
    path: "/formulas",
    exact: true,
    layout: DefaultLayout,
    component: Formulas,
  },
  {
    path: "/formulas/add",
    exact: true,
    layout: DefaultLayout,
    component: AddFormula,
  },
  {
    path: "/formulas/edit/:id",
    exact: true,
    layout: DefaultLayout,
    component: AddFormula,
  },

  {
    path: "/properties/add",
    exact: true,
    layout: DefaultLayout,
    component: AddProperty,
  },
  {
    path: "/properties",
    exact: true,
    layout: DefaultLayout,
    component: Properties,
  },
  {
    path: "/properties/edit/:id",
    exact: true,
    layout: DefaultLayout,
    component: AddProperty,
  },
  {
    path: "/properties/view/:id",
    exact: true,
    layout: DefaultLayout,
    component: ViewProperty,
  },
  {
    path: "/properties/:propertyId/account",
    exact: true,
    layout: DefaultLayout,
    component: Accounts,
  },
  {
    path: "/accounts",
    exact: true,
    layout: DefaultLayout,
    component: AccountsListing,
  },
  {
    path: "/accounts/edit/:accountId",
    exact: true,
    layout: DefaultLayout,
    component: EditAccount,
  },
  {
    path: "/accounts/view/:id",
    exact: true,
    layout: DefaultLayout,
    component: ViewAccount,
  },
  {
    path: "/deductions",
    exact: true,
    layout: DefaultLayout,
    component: Deductions,
  },
  {
    path: "/deductions/add",
    exact: true,
    layout: DefaultLayout,
    component: AddDeductions,
  },
  {
    path: "/deductions/edit/:id",
    exact: true,
    layout: DefaultLayout,
    component: AddDeductions,
  },
  {
    path: "/reports",
    exact: true,
    layout: DefaultLayout,
    component: AccountsListing,
    extraParams: { isReportsPage: true },
  },
];

export default Routes;
