import React, {useState} from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Collapse,
  NavItem,
  NavLink
} from "shards-react";
import { logout } from "../../../../actions/auth";
import PropTypes from "prop-types";
import UserImage from "./../../../../images/avatars/user.jpg"

function UserActions({ auth: { isAuthenticated, user }, logout }) {
  const [visible ,toggleUserActions] = useState(false);
  return (
    <NavItem tag={Dropdown} caret toggle={() => toggleUserActions(!visible)}>
        { user !== null && user.name !== null && (
        <DropdownToggle caret tag={NavLink} className="text-nowrap px-3">
          <img
            className="user-avatar rounded-circle mr-2"
            src={UserImage}
            alt="User Avatar"
          />{" "} 
          <span className="d-none d-md-inline-block mt-2">{isAuthenticated && user.name !== null ? user.name : 'Guest'}</span>
        </DropdownToggle>
        )}
        <Collapse tag={DropdownMenu} right small open={visible}>
          {/* <DropdownItem tag={Link} to="user-profile">
            <i className="material-icons">&#xE7FD;</i> Profile
          </DropdownItem> */}
          <DropdownItem tag={Link} to="/edit-user-profile">
            <i className="material-icons">&#xE8B8;</i> Edit Profile
          </DropdownItem>
          {/* <DropdownItem tag={Link} to="file-manager-list">
            <i className="material-icons">&#xE2C7;</i> Files
          </DropdownItem>
          <DropdownItem tag={Link} to="transaction-history">
            <i className="material-icons">&#xE896;</i> Transactions
          </DropdownItem> */}
          <DropdownItem divider />
          <DropdownItem tag={Link} onClick={logout} to="#!" className="text-danger">
            <i className="material-icons text-danger">&#xE879;</i> Logout
          </DropdownItem>
        </Collapse>
      </NavItem>
  )
}

UserActions.propTypes = {
  logout: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logout })(UserActions)

