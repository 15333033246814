import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { getUserById } from "../../actions/staff";
import Alert from "../../components/layout/Alert";
import { Card, CardBody, Container } from "shards-react";
import Moment from "react-moment";

function ViewUser({
  getUserById,
  staff: { user },
  match: {
    params: { id },
  },
}) {
  useEffect(() => {
    if (id) {
      getUserById(id);
    }
  }, [id, getUserById]);

  return (
    <Container fluid className="main-content-container px-4">
      {/* Page Header */}
      <div className="page-header py-4">
        <Card small className="card-post card-post--1">
          <CardBody>
            <Alert />
            <h1 className="large text-primary">
              {user.name ? `${user.name}'s Account` : ""}
            </h1>
            <p>
              {" "}
              <strong>Name:</strong> {user.name} <br />
              <strong>Email:</strong> {user.email}<br />
              <strong>Role:</strong> {user.role}<br />
              <strong>Joined:</strong> <Moment format={"Do MMM YYYY"}>{user.date}</Moment> {" "}
            </p>
            
            <Link to="/users" className="btn btn-secondary">
              Back
            </Link>{' '}
            <Link to={`/users/edit/${id}`} className="lead">
              Click here to Edit
            </Link>
          </CardBody>
        </Card>
      </div>
    </Container>
  );
}

ViewUser.propTypes = {
  getUserById: PropTypes.func.isRequired,
  staff: PropTypes.object,
};
const mapStateToProps = (state) => ({
  staff: state.staff,
});

export default connect(mapStateToProps, { getUserById })(ViewUser);
