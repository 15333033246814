import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { getPropertyById } from "../../actions/property";
import Alert from "../layout/Alert";
import { Card, CardBody, Container } from "shards-react";
import Moment from "react-moment";

function ViewProperty({
  getPropertyById,
  property: { property, loading },
  match: {
    params: { id },
  },
}) {
  useEffect(() => {
    if (id) {
      getPropertyById(id);
    }
  }, [id, getPropertyById, loading]);

  return (
    !loading && (
      <Container fluid className="main-content-container px-4">
        {/* Page Header */}
        <div className="page-header py-4">
          <Card small className="card-post card-post--1">
            <CardBody>
              <Alert />
              <h1 className="large text-primary">
                {property.name ? `${property.name}'s Account` : ""}
              </h1>
              <p>
                <strong>Title:</strong> {property.title} <br />
                <strong>Location:</strong> {property.location}
                <br />
                <strong>Formula:</strong>{" "}
                {property && property.Formula && property.Formula.title}
                <br />
                <strong>Referral:</strong>{" "}
                {property && property.Contact && property.Contact.name}
                <br />
                <strong>Created at:</strong>{" "}
                <Moment format={"Do MMM YYYY"}>{property.createdAt}</Moment>
              </p>
              <Link to="/properties" className="btn btn-secondary">
                Back
              </Link>{" "}
              <Link to={`/properties/edit/${id}`} className="lead">
                Click here to Edit
              </Link>
            </CardBody>
          </Card>
        </div>
      </Container>
    )
  );
}

ViewProperty.propTypes = {
  getPropertyById: PropTypes.func.isRequired,
  property: PropTypes.object,
};
const mapStateToProps = (state) => ({
  property: state.property,
});

export default connect(mapStateToProps, { getPropertyById })(ViewProperty);
