export const SidebarNavItems = () => {
  return [
    {
      title: "Dashboard",
      to: "/dashboard",
      htmlBefore: '<i class="fas fa-home"></i>',
      htmlAfter: "",
      accessToStaff: true,
    },
    {
      title: "Users",
      to: "/users",
      htmlBefore: '<i class="fas fa-users"></i>',
      htmlAfter: "",
      accessToStaff: false,
    },

    {
      title: "Formulas",
      to: "/formulas",
      htmlBefore: '<i class="fas fa-list"></i>',
      htmlAfter: "",
      accessToStaff: true,
    },
    {
      title: "Add New Formula",
      to: "/formulas/add",
      htmlBefore: '<i class="fas fa-plus"></i>',
      htmlAfter: "",
      accessToStaff: true,
    },
    {
      title: "Properties",
      to: "/properties",
      htmlBefore: '<i class="fas fa-list"></i>',
      htmlAfter: "",
      accessToStaff: true,
    },
    {
      title: "Add New Property",
      to: "/properties/add",
      htmlBefore: '<i class="fas fa-plus"></i>',
      htmlAfter: "",
      accessToStaff: true,
    },
    {
      title: "Accounts",
      to: "/accounts",
      htmlBefore: '<i class="fa fa-user"></i>',
      htmlAfter: "",
      accessToStaff: false,
    },
    {
      title: "Deduction",
      to: "/deductions",
      htmlBefore: '<i class="fa fa-minus"></i>',
      htmlAfter: "",
      accessToStaff: false,
    },
    {
      title: "Contacts",
      to: "/contacts",
      htmlBefore: '<i class="fa fa-id-card"></i>',
      htmlAfter: "",
      accessToStaff: true,
    },
    {
      title: "Add New Contact",
      to: "/contacts/add",
      htmlBefore: '<i class="fa fa-address-book"></i>',
      htmlAfter: "",
      accessToStaff: true,
    },
    {
      title: "Branches",
      to: "/branches",
      htmlBefore: '<i class="fa fa-code-branch"></i>',
      htmlAfter: "",
      accessToStaff: true,
    },
    {
      title: "Add New Branch",
      to: "/branches/add",
      htmlBefore: '<i class="fa fa-plus"></i>',
      htmlAfter: "",
      accessToStaff: true,
    },
    {
      title: "Reports",
      to: "/reports",
      htmlBefore: '<i class="fa fa-table"></i>',
      htmlAfter: "",
      accessToStaff: false,
    },
  ];
};
