import {
  GET_BRANCHES,
  GET_BRANCHES_FAILED,
  DELETE_BRANCH,
  BRANCH_ERROR,
  ADD_BRANCH,
  GET_BRANCH,
  GET_ALL_BRANCHES,
} from "../actions/types";

const initialState = {
  branches: [],
  branchesForDropdown: [],
  loading: true,
  error: {},
  branch: {},
};

export default function Branch(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_ALL_BRANCHES:
      return {
        ...state,
        branchesForDropdown: payload,
        loading: false,
        branch: {},
      };

    case GET_BRANCHES:
      return {
        ...state,
        branches: payload,
        loading: false,
        branch: {},
      };
    case GET_BRANCHES_FAILED:
      return {
        ...state,
        branches: null,
        loading: false,
        branch: {},
      };
    case DELETE_BRANCH:
      return {
        ...state,
        branches: state.branches.filter((branch) => branch.id !== payload),
        loading: false,
        branch: {},
      };
    case BRANCH_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
        branch: {},
      };
    case ADD_BRANCH:
      return {
        ...state,
        branches: [payload, ...state.branches],
        loading: false,
        branch: {},
      };
    case GET_BRANCH:
      return {
        ...state,
        branch: payload,
        loading: false,
      };
    default:
      return state;
  }
}
