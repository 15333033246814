import {
  GET_FORMULAS,
  GET_FORMULAS_FAILED,
  DELETE_FORMULA,
  FORMULA_ERROR,
  ADD_FORMULA,
  GET_FORMULA,
  GET_ALL_UNFILTERED_FORMULAS,
} from "../actions/types";

const initialState = {
  formulas: [],
  loading: true,
  error: {},
  formula: {},
  unfilteredFormulas: [],
};

export default function Formula(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_ALL_UNFILTERED_FORMULAS:
      return {
        ...state,
        unfilteredFormulas: payload,
        formula: {},
        loading: false,
      };
    case GET_FORMULAS:
      return {
        ...state,
        formulas: payload,
        loading: false,
        formula: {},
      };
    case GET_FORMULAS_FAILED:
      return {
        ...state,
        formulas: null,
        loading: false,
        formula: {},
      };
    case DELETE_FORMULA:
      return {
        ...state,
        formulas: state.formulas.filter((formula) => formula.id !== payload),
        loading: false,
        formula: {},
      };
    case FORMULA_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
        formula: {},
      };
    case ADD_FORMULA:
      return {
        ...state,
        formulas: [payload, ...state.formulas],
        loading: false,
        formula: {},
      };
    case GET_FORMULA:
      return {
        ...state,
        formula: payload,
        loading: false,
      };
    default:
      return state;
  }
}
