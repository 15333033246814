import React from "react";
import axios from "axios";
import { setAlert } from "./alert";
import {
  GET_ALL_USERS,
  GET_ALL_USERS_FAILED,
  DELETE_USER,
  USER_ERROR,
  ADD_USER,
  GET_USER,
  USER_LOADED,
  GET_ALL_UNFILTERED_USERS,
} from "./types";

// GET ALL UNFILTERED USERS
export const getAllUnfilteredUsers = () => async (dispatch) => {
  try {
    const res = await axios.get(`/api/users`);

    dispatch({
      type: GET_ALL_UNFILTERED_USERS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_ALL_USERS_FAILED,
    });
  }
};

// GET USERS
export const getAllUsers = (search) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/users/search/${search}`);

    dispatch({
      type: GET_ALL_USERS,
      payload: res.data,
    });
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
    }
    dispatch({
      type: GET_ALL_USERS_FAILED,
    });
  }
};

export const MemoizedGetAllUsers = React.memo(getAllUsers);

// Delete user
export const deleteUser = (id) => async (dispatch) => {
  try {
    await axios.delete(`/api/users/${id}`);

    dispatch({
      type: DELETE_USER,
      payload: id,
    });

    dispatch(setAlert("User Removed", "success"));
  } catch (err) {
    console.log(err);
    dispatch({
      type: USER_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

// Add user
export const addUser = (formData) => async (dispatch) => {
  try {
    let res = null;

    if (formData.id) {
      res = await axios.put(`/api/users/edit/${formData.id}`, formData);
    } else {
      res = await axios.post("/api/users/add", formData);
    }
    dispatch({
      type: ADD_USER,
      payload: res.data,
    });

    dispatch(
      setAlert(formData.id ? "User updated" : "User created", "success")
    );
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
    }
    dispatch({
      type: USER_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

// Get suer by id
export const getUserById = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/users/${id}`);

    dispatch({
      type: GET_USER,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: USER_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

// Get current user
export const getCurrentUserProfile = () => async (dispatch) => {
  try {
    const res = await axios.get(`/api/auth`);
    dispatch({
      type: GET_USER,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: USER_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

// Update profile
export const updateProfile = (formData) => async (dispatch) => {
  try {
    const res = await axios.put(`/api/auth/edit/`, formData);
    dispatch({
      type: USER_LOADED,
      payload: res.data,
    });

    dispatch(setAlert("Profile updated", "success"));
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
    }
    dispatch({
      type: USER_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};
