import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

function AccountItem({ account, reportsPage }) {
  return (
    <Fragment>
      {reportsPage !== true && (
        <Link to={`/accounts/edit/${account.id}`} title="Edit">
          <i className="fa fa-pencil pr-2 text-warning" />
        </Link>
      )}
      <Link
        to={{
          pathname: `/accounts/view/${account.id}`,
          state: { accountId: account.id },
        }}
      >
        <i className="fa fa-eye" />
      </Link>{" "}
    </Fragment>
  );
}

AccountItem.propTypes = {
  account: PropTypes.object.isRequired,
};

export default connect(null, {})(AccountItem);
