import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import {
  createDeduction,
  getDeductionById,
  editDeduction,
} from "../../actions/deduction";
import Alert from "../layout/Alert";
import { Formik } from "formik";
import * as yup from "yup";
import {
  Card,
  CardBody,
  FormInput,
  FormGroup,
  InputGroup,
  Container,
  FormSelect,
} from "shards-react";

const validationSchema = yup.object({
  amount: yup.string().required().label("*Amount"),
  agent_id: yup.string().required().label("*Agent"),
});

function AddEdit({
  getDeductionById,
  createDeduction,
  editDeduction,
  deduction: { deduction, loading },
  match: {
    params: { id },
  },

  users,
}) {
  const [focusedInput, setFocusedInput] = useState();
  useEffect(() => {
    if (
      id &&
      Object.keys(deduction).length === 0 &&
      deduction.constructor === Object
    ) {
      getDeductionById(id);
    }
  }, [id, getDeductionById, deduction]);

  return (
    <Formik
      enableReinitialize
      initialValues={{
        amount: id ? deduction.amount : "0",
        agent_id: id ? deduction.agent_id : "",
      }}
      onSubmit={(values) => {
        const formData = {
          amount: values.amount,
          agent_id: values.agent_id,
          id: id,
        };
        if (id) {
          editDeduction(formData, id);
        } else {
          createDeduction(formData);
        }
      }}
      validationSchema={validationSchema}
    >
      {(formikProps) => (
        <Container fluid className="main-content-container px-4">
          {/* Page Header */}
          <div className="page-header py-4">
            <Card small className="card-post card-post--1">
              <CardBody>
                <h1 className="large text-primary">
                  {id ? "Edit" : "Add"} Deduction
                </h1>
                <p className="">
                  <i className="fas fa-user"></i> {id ? "Update" : "Create"}{" "}
                  deduction details
                </p>
                <form className="form col col-lg-6 col-md-12">
                  <FormGroup>
                    <InputGroup className="mb-3">
                      <FormInput
                        onFocus={(e) => setFocusedInput(e.target.name)}
                        invalid={
                          formikProps.errors && formikProps.errors.amount
                            ? true
                            : false
                        }
                        placeholder="Amount"
                        value={formikProps.values.amount}
                        onChange={formikProps.handleChange}
                        name="amount"
                        type="number"
                      />
                    </InputGroup>
                    <small className="text-danger">
                      {focusedInput === "amount" && formikProps.errors.amount}
                    </small>
                  </FormGroup>
                  <FormGroup>
                    <InputGroup className="">
                      <FormSelect
                        onFocus={(e) => setFocusedInput(e.target.name)}
                        invalid={
                          formikProps.errors && formikProps.errors.agent_id
                            ? true
                            : false
                        }
                        value={formikProps.values.agent_id}
                        onChange={formikProps.handleChange}
                        name="agent_id"
                      >
                        <option value="">Choose</option>

                        {users.length > 0 &&
                          users.map((user) => (
                            <option key={user.id} value={user.id}>
                              {user.name}
                            </option>
                          ))}
                      </FormSelect>
                    </InputGroup>
                    <small className="text-danger">
                      {focusedInput === "agent_id" &&
                        formikProps.errors.agent_id}
                    </small>
                  </FormGroup>
                  <input
                    type="submit"
                    className="bg-primary text-white text-center rounded btn btn-primary"
                    value="Save"
                    onClick={formikProps.handleSubmit}
                    disabled={!formikProps.isValid}
                  />{" "}
                  <Link to="/deductions" className="btn btn-secondary">
                    Back
                  </Link>
                </form>
                <div className="mt-3">
                  <Alert />
                </div>
              </CardBody>
            </Card>
          </div>
        </Container>
      )}
    </Formik>
  );
}

AddEdit.propTypes = {
  getDeductionById: PropTypes.func.isRequired,
  createDeduction: PropTypes.func.isRequired,
  editDeduction: PropTypes.func.isRequired,
  deduction: PropTypes.object,
};
const mapStateToProps = (state) => ({
  deduction: state.deduction,
  users: state.staff.unfilteredUsers.filter((item) => item.role === "Agent"),
});

export default connect(mapStateToProps, {
  getDeductionById,
  createDeduction,
  editDeduction,
})(AddEdit);
